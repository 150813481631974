<template>
  <v-card
    tile
    elevation="0"
    :class="grid.outer"
  >
    <v-row
      no-gutters
      :justify="component.dialog.icon.justify"
      :style="'min-height: ' + component.dialog.icon.boxHeight + 'px'"
      :align="component.dialog.icon.align"
    >
      <v-col cols="auto">
        <v-card
          tile
          elevation="0"
          :class="grid.inner"
          :height="component.dialog.icon.size"
        >
          <img
            :style="svgStyle"
            v-if="card.cardType === 'leaderboard'"
            :src="'/img/icons/' + card.iconToRender.svgFile"
            :height="component.dialog.icon.size"
          />
          <v-icon
            color="white"
            :style="svgStyle"
            :size="component.dialog.icon.size"
            v-else-if="card.cardType === 'offer'"
          >fa-light fa-receipt</v-icon>
          <img
            :style="svgStyle"
            v-else
            :src="'/img/icons/006-objective.svg'"
            :height="component.dialog.icon.size"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
 export default {
  props: ['card' , 'component'],
  computed: {
    webTheme() {
      return this.$store.getters.currentProgram.webTheme
    },
    grid() {
      return this.$store.getters.grid
    },
    svgStyle()  {
      var opacity = 0.5
      return 'opacity: ' + opacity + ' !important;' 
    }
  }
}
</script>

<style scoped>

</style>


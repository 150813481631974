<template>
  <v-container
    fluid
    tile
    class="pa-0"
    :style="'background-color: ' + this.webTheme.colors.canvas.hexa + ';'"
  >
    <v-row no-gutters justify="center">
      <v-col xs="12" :sm="component.settings.cols">
        <v-sheet tile :color="this.webTheme.colors.content.hexa" :style="block">
          <v-row no-gutters justify="center">
            <v-col>
              <view-text :settings="component.title"></view-text>
            </v-col>
          </v-row>

          <v-row v-for="(category, id) in categories" :key="id" no-gutters>
            <v-col>
              <v-row no-gutters>
                <view-text
                  :settings="component.category"
                  :value="category.title"
                ></view-text>
              </v-row>

              <v-row
                v-if="component.card.group.slider === 'slide'"
                :justify="component.card.group.justify"
                no-gutters
              >
                <v-col :lg="component.card.group.cols">
                  <v-card
                    tile
                    elevation="0"
                    :class="grid.inner"
                    background-color="transparent"
                  >
                    <v-slide-group
                      :dark="component.card.group.dark"
                      show-arrows="always"
                    >
                      <v-slide-item v-for="(card, i) in rewards" :key="i">
                        <v-card
                          :class="classes"
                          :style="styles + ';' + gradient"
                          :height="cardHeight"
                          :width="component.card.width"
                          :elevation="component.card.elevation"
                          @click="openRewardPopUp(card)"
                        >
                          <v-img
                            v-if="component.image.show === true"
                            :height="component.image.height + 'px'"
                            :lazy-src="
                              card.externalImageUrl
                                ? card.externalImageUrl
                                : card.image.mediumUrl
                            "
                            :src="
                              card.externalImageUrl
                                ? card.externalImageUrl
                                : card.image.mediumUrl
                            "
                            :class="card.radius ? 'radius' : ''"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                          <v-card-text :style="cardText">
                            <v-row no-gutters v-if="card.title">
                              <v-col>
                                <view-text
                                  :settings="component.heading"
                                  :value="card.title"
                                />
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col>
                                <view-text
                                  :settings="component.points"
                                  :value="
                                    formatPoints(card.points) +
                                      ' ' +
                                      pointsLabel
                                  "
                                />
                              </v-col>
                            </v-row>
                            <v-row
                              no-gutters
                              v-if="
                                card.body && component.settings.previewRows > 0
                              "
                            >
                              <v-col>
                                <view-text
                                  :settings="component.body"
                                  :value="card.body"
                                  :lines="component.settings.previewRows"
                                />
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-slide-item>
                    </v-slide-group>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-else no-gutters :justify="component.card.group.justify">
                <v-col :lg="component.card.group.cols">
                  <v-card tile elevation="0" :class="grid.inner">
                    <v-row
                      class="mx-2 mx-lg-0"
                      :justify="component.card.group.justify"
                    >
                      <v-card
                        v-for="(card, i) in category.rewardList"
                        :key="i"
                        :class="classes"
                        :height="component.card.height"
                        :width="component.card.width"
                        :style="styles + ';' + gradient"
                        :elevation="component.card.elevation"
                        @click="openRewardPopUp(card)"
                      >
                        <v-img
                          v-if="component.image.show === true"
                          :height="component.image.height + 'px'"
                          :lazy-src="
                            card.externalImageUrl
                              ? card.externalImageUrl
                              : card.image.mediumUrl
                          "
                          :src="
                            card.externalImageUrl
                              ? card.externalImageUrl
                              : card.image.mediumUrl
                          "
                          :class="card.radius ? 'radius' : ''"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                        <div>
                          <v-row no-gutters v-if="card.title">
                            <v-col>
                              <view-text
                                :settings="component.heading"
                                :value="card.title"
                              />
                            </v-col>
                          </v-row>
                          <v-row no-gutters>
                            <v-col>
                              <view-text
                                :settings="component.points"
                                :value="
                                  formatPoints(card.points) + ' ' + pointsLabel
                                "
                              />
                            </v-col>
                          </v-row>
                          <v-row no-gutters v-if="card.body">
                            <v-col>
                              <view-text
                                :settings="component.body"
                                :value="card.body"
                                :lines="component.settings.previewRows"
                              />
                            </v-col>
                          </v-row>
                        </div>
                      </v-card>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>

    <reward-dialog
      v-if="showReward"
      :is-visible="showReward"
      :on-close="closeRewardPopUp"
      :reward="activeReward"
      :component="component"
    ></reward-dialog>
  </v-container>
</template>

<script>
import numeral from "numeral";
import ViewText from "../Text.vue";
import RewardDialog from "./RewardDialog.vue";
import moment from "moment";

export default {
  components: {
    ViewText,
    RewardDialog
  },
  props: ["component", "index"],
  created() {
    this.$store.dispatch("loadRewards");
    this.$store.dispatch("loadRewardCategories");
  },
  data: function() {
    return {
      showReward: false,
      activeReward: {},
      rewardCategories: []
    };
  },
  computed: {
    rewards() {
      const arr = this.$store.state.rewards.rewards;
      const idToMatch = this.component.settings.selectedRewardCategory;
      return arr.filter(obj =>
        obj.rewardCategories.some(id => id === idToMatch)
      );
    },
    categories() {
      return this.$store.state.rewards.rewardCategories.filter(
        el => el.id === this.component.settings.selectedRewardCategory
      );
    },
    pointsLabel() {
      return this.$store.getters.currentProgram.points.label;
    },
    cardHeight() {
      if (this.component.card.autoHeight === true) {
        return "auto";
      }
      return this.component.card.height;
    },
    primary() {
      return this.webTheme.colors.primary.hex;
    },
    classes() {
      var classes = this.component.card.rounded;
      return classes;
    },
    grid() {
      return this.$store.getters.grid;
    },
    block() {
      var padding = this.component.block.padding;
      return (
        this.grid.block +
        "px ;" +
        "padding-left: " +
        padding.left +
        "px ;" +
        "padding-right: " +
        padding.right +
        "px ;" +
        "padding-top: " +
        padding.top +
        "px ;" +
        "padding-bottom: " +
        padding.bottom +
        "px ;"
      );
    },
    cardText() {
      var padding = this.component.card.textAreaPadding;
      return (
        "padding-left: " +
        padding.left +
        "px ;" +
        "padding-right: " +
        padding.right +
        "px ;" +
        "padding-top: " +
        padding.top +
        "px ;" +
        "padding-bottom: " +
        padding.bottom +
        "px ;"
      );
    },
    gradient() {
      if (this.component.card.color.type === "gradient") {
        var color = this.component.card.color;
        var gradient =
          "background: linear-gradient(" +
          color.direction +
          ", rgba(" +
          color.gradientFrom.rgba.r +
          ", " +
          color.gradientFrom.rgba.g +
          ", " +
          color.gradientFrom.rgba.b +
          ", " +
          color.gradientFrom.rgba.a +
          ") " +
          ", rgba(" +
          color.gradientTo.rgba.r +
          ", " +
          color.gradientTo.rgba.g +
          ", " +
          color.gradientTo.rgba.b +
          ", " +
          color.gradientTo.rgba.a +
          ")" +
          color.gradientHeight +
          "%)";
        return gradient;
        // return 'background: linear-gradient(to top, rgba(0, 0, 255, 100), rgba(255, 0, 0, 100) 50%)'
      } else {
        return "background: " + this.component.card.color.solidColor.hexa + ";";
      }
    },
    styles() {
      var styles =
        "margin-bottom: " +
        this.component.card.margin.bottom +
        "px ;" +
        "margin-top: " +
        this.component.card.margin.top +
        "px ;" +
        "margin-left: " +
        this.component.card.margin.left +
        "px ;" +
        "margin-right: " +
        this.component.card.margin.right +
        "px ;" +
        "padding-bottom: " +
        this.component.card.padding.bottom +
        "px ;" +
        "padding-top: " +
        this.component.card.padding.top +
        "px ;" +
        "padding-left: " +
        this.component.card.padding.left +
        "px ;" +
        "padding-right: " +
        this.component.card.padding.right +
        "px ;" +
        "border: " +
        this.component.card.borderSize +
        "px solid " +
        this.component.card.borderColor.hexa +
        "; ";
      return styles;
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme;
    },
    primaryMenus() {
      return this.$store.getters.primaryMenus;
    },
    screenDetail() {
      return (
        this.primaryMenus.find(
          menuDetail =>
            menuDetail.content ===
            this.component.settings.selectedRewardCatalogue
        ) || {}
      ); // this.$route.params.id
    }
  },
  watch: {
    screenDetail(value) {
      if (
        this.screenDetail.rewardCatalogues &&
        Object.values(this.screenDetail.rewardCatalogues).length > 0
      ) {
        this.rewardCategories = Object.values(
          this.screenDetail.rewardCatalogues
        );
      }
    }
  },
  mounted() {
    this.rewardCategories =
      (this.screenDetail.rewardCatalogues &&
        Object.values(this.screenDetail.rewardCatalogues)) ||
      [];
  },
  methods: {
    date(date) {
      return moment(date).format("ddd MMMM Do YYYY");
    },
    getAuthorName(adminId) {
      return this.blogAuthorMap[adminId];
    },
    openRewardPopUp(value) {
      this.activeReward = value;
      this.showReward = true;
    },
    closeRewardPopUp() {
      this.showReward = false;
    },
    formatPoints(val) {
      return numeral(val).format("0,0");
    }
  }
};
</script>

<style scoped>
.radius {
  border-radius: 15px !important;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"id":"dialog","width":_vm.component.dialog.width,"fullscreen":_vm.component.dialog.fullscreen,"persistent":"","scrollable":""},model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[_c('v-card',{class:_vm.classes,style:(_vm.styles + ';' + _vm.gradient)},[(
        _vm.component.dialog.title.floatText === 'text-right' &&
          _vm.component.dialog.fullscreen === true
      )?_c('v-btn',{staticStyle:{"z-index":"100"},attrs:{"top":"","left":"","absolute":"","small":"","icon":""},on:{"click":_vm.onClose}},[_c('v-icon',{attrs:{"color":_vm.component.dialog.title.color}},[_vm._v("clear")])],1):_vm._e(),(
        _vm.component.dialog.title.floatText !== 'text-right' &&
          _vm.component.dialog.fullscreen === true
      )?_c('v-btn',{staticStyle:{"z-index":"100"},attrs:{"top":"","right":"","absolute":"","small":"","icon":""},on:{"click":_vm.onClose}},[_c('v-icon',{attrs:{"color":_vm.component.dialog.title.color}},[_vm._v("clear")])],1):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[(
            _vm.component.dialog.icon.placement === 'left' &&
              _vm.component.dialog.icon.show === true
          )?_c('view-dialog-icon',{attrs:{"card":_vm.progressData,"component":_vm.component}}):_vm._e()],1),_c('v-col',[(
            _vm.component.dialog.icon.placement === 'top' &&
              _vm.component.dialog.icon.show === true
          )?_c('v-row',{attrs:{"no-gutters":"","justify":_vm.component.dialog.icon.justify}},[_c('view-dialog-icon',{attrs:{"card":_vm.progressData,"component":_vm.component}})],1):_vm._e(),_c('view-text',{attrs:{"settings":_vm.component.dialog.title,"value":_vm.progressData.displayTitle}}),(
            _vm.component.dialog.icon.placement === 'bottom' &&
              _vm.component.dialog.icon.show === true
          )?_c('v-row',{attrs:{"no-gutters":"","justify":_vm.component.dialog.icon.justify}},[_c('view-dialog-icon',{attrs:{"card":_vm.progressData,"component":_vm.component}})],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"auto"}},[(
            _vm.component.dialog.icon.placement === 'right' &&
              _vm.component.dialog.icon.show === true
          )?_c('view-dialog-icon',{attrs:{"card":_vm.progressData,"component":_vm.component}}):_vm._e()],1)],1),_c('perfect-scrollbar',[_c('v-card-text',{staticClass:"ma-0 pa-0"},[_c('v-card',{style:(_vm.summaryTableStyles),attrs:{"color":"transparent","elevation":"0"}},[(_vm.titleTableData.length !== 0)?_c('v-data-table',{staticClass:"table",style:(_vm.cssVars),attrs:{"headers":_vm.titleHeaders,"items":_vm.titleTableData,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
          var item = ref.item;
return [_c('v-row',{staticClass:"pa-2",attrs:{"no-gutters":"","justify":_vm.iconAlign}},[(item.icon)?_c('v-img',{attrs:{"src":("/img/icons/" + (item.icon)),"max-width":_vm.iconSize,"max-height":_vm.iconSize,"contain":""}}):_vm._e()],1)]}},{key:"item.avatar",fn:function(ref){
          var item = ref.item;
return [_c('v-row',{staticClass:"pa-2",attrs:{"no-gutters":"","justify":_vm.iconAlign}},[_c('v-avatar',{attrs:{"size":_vm.avatarSize}},[_c('v-img',{attrs:{"src":("" + (item[_vm.avatarId])),"alt":"alt"}})],1)],1)]}},{key:"item.qualifier",fn:function(ref){
          var item = ref.item;
return [_c('v-row',{staticClass:"pa-2",attrs:{"no-gutters":"","justify":_vm.iconAlign}},[(item[_vm.qualifierId])?_c('v-img',{attrs:{"src":("/img/icons/" + (item[_vm.qualifierId])),"max-width":_vm.iconSize,"max-height":_vm.iconSize,"contain":""}}):_vm._e()],1)]}}],null,true)}):_vm._e()],1),_c('v-card',{style:(_vm.detailTableStyles),attrs:{"color":"transparent","elevation":"0"}},[_c('v-data-table',{staticClass:"table",style:(_vm.cssVars),attrs:{"headers":_vm.headers,"items":_vm.tableData,"hide-default-footer":"","page":_vm.page,"items-per-page":_vm.itemsPerPage},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
          var item = ref.item;
return [_c('v-row',{staticClass:"pa-2",attrs:{"no-gutters":"","justify":_vm.iconAlign}},[(item.icon)?_c('v-img',{attrs:{"src":("/img/icons/" + (item.icon)),"max-width":_vm.iconSize,"max-height":_vm.iconSize,"contain":""}}):_vm._e()],1)]}},{key:"item.avatar",fn:function(ref){
          var item = ref.item;
return [_c('v-row',{staticClass:"pa-2",attrs:{"no-gutters":"","justify":_vm.iconAlign}},[_c('v-avatar',{attrs:{"size":_vm.avatarSize}},[_c('v-img',{attrs:{"src":("" + (item[_vm.avatarIdx])),"alt":"alt"}})],1)],1)]}},{key:"item.qualifier",fn:function(ref){
          var item = ref.item;
return [_c('v-row',{staticClass:"pa-2",attrs:{"no-gutters":"","justify":_vm.iconAlign}},[(item[_vm.qualifierId])?_c('v-img',{attrs:{"src":("/img/icons/" + (item[_vm.qualifierId])),"max-width":_vm.iconSize,"max-height":_vm.iconSize,"contain":""}}):_vm._e()],1)]}}],null,true)}),(_vm.pageCount > 1)?_c('v-row',{staticClass:"mt-2",attrs:{"justify":"center","no-gutters":"","align":"center"}},[(_vm.page > 1)?_c('v-btn',{attrs:{"rounded":"","x-small":"","elevation":"0","color":"transparent"},on:{"click":function($event){_vm.page--}}},[_c('v-icon',{attrs:{"color":this.component.dialog.content.heading.color,"size":"20"}},[_vm._v("chevron_left")])],1):_vm._e(),_vm._l((_vm.pageCount),function(page){return _c('v-btn',{key:page,attrs:{"x-small":"","elevation":"0","color":"transparent","rounded":""},on:{"click":function($event){return _vm.handlePage(page)}}},[_c('span',{class:_vm.paginationStyle(page),style:(_vm.cssVars)},[_vm._v(" "+_vm._s(page)+" ")])])}),(_vm.page < _vm.pageCount)?_c('v-btn',{attrs:{"rounded":"","x-small":"","elevation":"0","color":"transparent"},on:{"click":function($event){_vm.page++}}},[_c('v-icon',{attrs:{"color":this.component.dialog.content.heading.color,"size":"20"}},[_vm._v("chevron_right")])],1):_vm._e()],2):_vm._e()],1)],1)],1),_c('v-card-actions',{staticClass:"ma-0 pa-0"},[_c('v-spacer'),(_vm.component.dialog.fullscreen !== true)?_c('v-btn',{attrs:{"text":"","color":_vm.component.dialog.title.color},on:{"click":function($event){_vm.resetPage();
          _vm.onClose();}}},[_vm._v(_vm._s(_vm.$t("CLOSE")))]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
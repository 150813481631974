var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",style:('background-color: ' + this.webTheme.colors.canvas.hexa),attrs:{"fluid":"","tile":""}},[_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"xs":"12","sm":_vm.component.settings.cols}},[_c('v-sheet',{attrs:{"height":_vm.height,"tile":"","color":_vm.component.settings.color.hexa}},[(_vm.component.settings.mode === 'color')?[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":"","justify":_vm.component.block.justify,"align":_vm.component.block.align}},[_c('v-col',{attrs:{"xs":"12","sm":_vm.component.block.cols}},[_c('v-col',[_c('v-card',{class:_vm.titleBlock,style:(_vm.topMargin + _vm.bottomMargin),attrs:{"tile":"","elevation":"0"}},[_c('v-row',{attrs:{"no-gutters":""}},[(
                        _vm.component.avatar.placement === 'left' &&
                          _vm.component.avatar.image &&
                          _vm.component.avatar.show
                      )?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',{style:('height: ' + _vm.component.avatar.boxHeight + 'px;'),attrs:{"justify":_vm.component.avatar.justify}},[_c('media-upload',{attrs:{"id":_vm.currentUser._id,"currentImageOnFile":_vm.currentUser.image,"externalImageUrl":_vm.currentUser.externalImageUrl,"showMetaData":false,"radius":false,"avatar":true,"avatarSize":_vm.component.avatar.height},on:{"onImageSaved":_vm.handleImageSaved,"onImageProcessing":_vm.handleImageProcessing}})],1)],1):_vm._e(),_c('v-col',[_c('v-row',{style:('height: ' + _vm.component.avatar.boxHeight + 'px;'),attrs:{"justify":_vm.component.avatar.justify}},[(
                            _vm.component.avatar.placement === 'top' &&
                              _vm.component.avatar.image &&
                              _vm.component.avatar.show
                          )?_c('media-upload',{attrs:{"id":_vm.currentUser._id,"currentImageOnFile":_vm.currentUser.image,"externalImageUrl":_vm.currentUser.externalImageUrl,"showMetaData":false,"radius":false,"avatar":true,"avatarSize":_vm.component.avatar.height},on:{"onImageSaved":_vm.handleImageSaved,"onImageProcessing":_vm.handleImageProcessing}}):_vm._e()],1),_c('view-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.component.name.show),expression:"component.name.show"}],attrs:{"settings":_vm.component.name,"value":_vm.currentUser.firstname + ' ' + _vm.currentUser.lastname}}),_c('div',{staticClass:"mb-4"},_vm._l((_vm.currentUser.companies),function(company,i){return _c('v-row',{key:'company' + i,attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-col',[_c('view-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentUser.companies.length > 0),expression:"currentUser.companies.length > 0"}],attrs:{"settings":_vm.component.email,"value":_vm.getCompanyName(company)}})],1)],1)}),1),_c('view-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.component.email.show),expression:"component.email.show"}],attrs:{"settings":_vm.component.email,"value":_vm.currentUser.email}}),_c('view-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.component.phone.show),expression:"component.phone.show"}],attrs:{"settings":_vm.component.phone,"value":_vm.currentUser.phone}}),_c('view-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.component.points.show),expression:"component.points.show"}],attrs:{"settings":_vm.component.points,"value":_vm._f("number")(_vm.currentUser.balance),"suffix":_vm.suffix}}),_c('v-row',{style:('height: ' + _vm.component.avatar.boxHeight + 'px;'),attrs:{"justify":_vm.component.avatar.justify}},[(
                            _vm.component.avatar.placement === 'bottom' &&
                              _vm.component.avatar.image &&
                              _vm.component.avatar.show
                          )?_c('media-upload',{attrs:{"id":_vm.currentUser._id,"currentImageOnFile":_vm.currentUser.image,"externalImageUrl":_vm.currentUser.externalImageUrl,"showMetaData":false,"radius":false,"avatar":true,"avatarSize":_vm.component.avatar.height},on:{"onImageSaved":_vm.handleImageSaved,"onImageProcessing":_vm.handleImageProcessing}}):_vm._e()],1)],1),(
                        _vm.component.avatar.placement === 'right' &&
                          _vm.component.avatar.image &&
                          _vm.component.avatar.show
                      )?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',{style:('height: ' + _vm.component.avatar.boxHeight + 'px;'),attrs:{"justify":_vm.component.avatar.justify}},[_c('media-upload',{attrs:{"id":_vm.currentUser._id,"currentImageOnFile":_vm.currentUser.image,"externalImageUrl":_vm.currentUser.externalImageUrl,"showMetaData":false,"radius":false,"avatar":true,"avatarSize":_vm.component.avatar.height},on:{"onImageSaved":_vm.handleImageSaved,"onImageProcessing":_vm.handleImageProcessing}})],1)],1):_vm._e()],1)],1)],1)],1)],1)]:_vm._e(),(_vm.component.settings.mode === 'image')?[_c('v-img',{attrs:{"src":_vm.component.background.image,"gradient":_vm.gradient,"height":_vm.imageHeight}},[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":"","justify":_vm.component.block.justify,"align":_vm.component.block.align}},[_c('v-col',{attrs:{"xs":"12","sm":_vm.component.block.cols}},[_c('v-col',[_c('v-card',{class:_vm.titleBlock,style:(_vm.topMargin + _vm.bottomMargin),attrs:{"tile":"","elevation":"0"}},[_c('v-row',{attrs:{"no-gutters":""}},[(
                          _vm.component.avatar.placement === 'left' &&
                            _vm.component.avatar.image &&
                            _vm.component.avatar.show
                        )?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',{style:('height: ' + _vm.component.avatar.boxHeight + 'px;'),attrs:{"justify":_vm.component.avatar.justify}},[_c('media-upload',{attrs:{"id":_vm.currentUser._id,"currentImageOnFile":_vm.currentUser.image,"externalImageUrl":_vm.currentUser.externalImageUrl,"showMetaData":false,"radius":false,"avatar":true,"avatarSize":_vm.component.avatar.height},on:{"onImageSaved":_vm.handleImageSaved,"onImageProcessing":_vm.handleImageProcessing}})],1)],1):_vm._e(),_c('v-col',[_c('v-row',{style:('height: ' + _vm.component.avatar.boxHeight + 'px;'),attrs:{"justify":_vm.component.avatar.justify}},[_c('media-upload',{attrs:{"id":_vm.currentUser._id,"currentImageOnFile":_vm.currentUser.image,"externalImageUrl":_vm.currentUser.externalImageUrl,"showMetaData":false,"radius":false,"avatar":true,"avatarSize":_vm.component.avatar.height},on:{"onImageSaved":_vm.handleImageSaved,"onImageProcessing":_vm.handleImageProcessing}})],1),_c('view-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.component.name.show),expression:"component.name.show"}],attrs:{"settings":_vm.component.name,"value":_vm.currentUser.firstname + ' ' + _vm.currentUser.lastname}}),_c('view-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.component.email.show),expression:"component.email.show"}],attrs:{"settings":_vm.component.email,"value":_vm.currentUser.email}}),_c('view-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.component.phone.show),expression:"component.phone.show"}],attrs:{"settings":_vm.component.phone,"value":_vm.currentUser.phone}}),_c('view-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.component.points.show),expression:"component.points.show"}],attrs:{"settings":_vm.component.points,"value":_vm._f("number")(_vm.currentUser.balance),"suffix":_vm.suffix}}),_c('v-row',{style:('height: ' + _vm.component.avatar.boxHeight + 'px;'),attrs:{"justify":_vm.component.avatar.justify}},[(
                              _vm.component.avatar.placement === 'bottom' &&
                                _vm.component.avatar.image &&
                                _vm.component.avatar.show
                            )?_c('media-upload',{attrs:{"id":_vm.currentUser._id,"currentImageOnFile":_vm.currentUser.image,"externalImageUrl":_vm.currentUser.externalImageUrl,"showMetaData":false,"radius":false,"avatar":true,"avatarSize":_vm.component.avatar.height},on:{"onImageSaved":_vm.handleImageSaved,"onImageProcessing":_vm.handleImageProcessing}}):_vm._e()],1)],1),(
                          _vm.component.avatar.placement === 'right' &&
                            _vm.component.avatar.image &&
                            _vm.component.avatar.show
                        )?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',{style:('height: ' + _vm.component.avatar.boxHeight + 'px;'),attrs:{"justify":_vm.component.avatar.justify}},[_c('media-upload',{attrs:{"id":_vm.currentUser._id,"currentImageOnFile":_vm.currentUser.image,"externalImageUrl":_vm.currentUser.externalImageUrl,"showMetaData":false,"radius":false,"avatar":true,"avatarSize":_vm.component.avatar.height},on:{"onImageSaved":_vm.handleImageSaved,"onImageProcessing":_vm.handleImageProcessing}})],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)]:_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-container
      fluid
      tile
      class="pa-0"
      :style="'background-color: ' + this.webTheme.colors.header.hexa"
    >
      <v-row no-gutters justify="center">
        <v-col xs="12" :sm="component.settings.cols">
          <v-sheet
            :color="component.settings.color.hexa"
            style="z-index: 1; position: relative;"
          >
            <v-app-bar
              fixed
              elevate-on-scroll
              :style="barClass"
              :height="webTheme.shared.menuHeight + 'px; '"
              :class="grid.block"
            >
              <v-app-bar-nav-icon
                v-if="
                  ($vuetify.breakpoint.xs && !siteDrawer) ||
                    ($vuetify.breakpoint.sm && !siteDrawer)
                "
                @click="handleSiteDrawer()"
                :color="
                  this.$store.getters.currentProgram.webTheme.colors.primary.hex
                "
                class="ml-3"
              ></v-app-bar-nav-icon>
              <template v-else-if="!siteDrawer">
                <img
                  v-if="component.logo.align === 'left' && component.logo.image"
                  :src="component.logo.image"
                  :width="component.logo.width"
                  class="mr-4"
                />
                <v-row v-if="component.menuGroup.group" no-gutters>
                  <v-col xs="12" :sm="component.menuGroup.cols">
                    <v-row
                      :justify="component.menuGroup.justify"
                      align="center"
                      no-gutters
                    >
                      <v-col>
                        <v-card tile elevation="0" :class="grid.inner">
                          <v-row
                            align="center"
                            :justify="component.menuGroup.justify"
                            no-gutters
                          >
                            <v-btn
                              text
                              v-for="(menu, i) in primaryMenus"
                              :key="'a' + i"
                              :to="menu.to"
                            >
                              <view-text
                                :settings="component.label"
                                :value="menu.title"
                              ></view-text>
                            </v-btn>

                            <img
                              v-if="
                                component.logo.align === 'center' &&
                                  component.logo.image
                              "
                              :src="component.logo.image"
                              :width="component.logo.width"
                            />

                            <span
                              v-if="currentProgram.rewards.active"
                              class="text-uppercase px-5"
                              :style="
                                'margin-right: ' +
                                  (component.label.marginRight + 15) +
                                  'px; margin-left: ' +
                                  (component.label.marginLeft + 15) +
                                  'px; letter-spacing: .0892857143em;'
                              "
                            >
                              <view-text
                                :settings="component.label"
                                :value="currentUser.balance | number"
                              ></view-text>
                            </span>

                            <v-btn v-if="currentProgram.rewards.cart" text>
                              <v-icon
                                :size="component.label.size * 1.5"
                                left
                                :color="component.label.color"
                                :style="
                                  'margin-left: ' +
                                    component.label.marginLeft +
                                    'px; '
                                "
                              >
                                shopping_cart
                              </v-icon>
                              <view-text
                                :settings="component.label"
                                value="cart"
                                :style="
                                  'margin-left: -' +
                                    component.label.marginLeft +
                                    'px !important; '
                                "
                              ></view-text>
                            </v-btn>

                            <v-btn text>
                              <v-icon
                                :size="component.label.size * 1.5"
                                left
                                :color="component.label.color"
                                :style="
                                  'margin-left: ' +
                                    component.label.marginLeft +
                                    'px; '
                                "
                              >
                                person
                              </v-icon>
                              <view-text
                                :settings="component.label"
                                :value="name"
                                :style="
                                  'margin-left: -' +
                                    component.label.marginLeft +
                                    'px !important; '
                                "
                              ></view-text>
                            </v-btn>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row v-else no-gutters>
                  <v-col xs="12" :sm="component.menuGroup.cols">
                    <v-row
                      :justify="component.menuGroup.justify"
                      align="center"
                      no-gutters
                    >
                      <v-col>
                        <v-card tile elevation="0" :class="grid.inner">
                          <v-row
                            align="center"
                            :justify="component.buttons.left.justify"
                            no-gutters
                          >
                            <v-btn
                              text
                              v-for="(menu, i) in primaryMenus"
                              :key="'b' + i"
                              :to="menu.to"
                            >
                              <view-text
                                :settings="component.label"
                                :value="menu.title"
                              ></view-text>
                            </v-btn>
                          </v-row>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="
                          component.logo.align === 'center' &&
                            component.logo.image
                        "
                        cols="auto"
                        class="mx-3"
                      >
                        <img
                          :src="component.logo.image"
                          :width="component.logo.width"
                        />
                      </v-col>

                      <v-col>
                        <v-card tile elevation="0" :class="grid.inner">
                          <v-row
                            align="center"
                            :justify="component.buttons.right.justify"
                            no-gutters
                          >
                            <v-btn
                              v-if="currentProgram.rewards.active"
                              text
                              @click="handleMemberPointsTable()"
                              :style="
                                'margin-right: ' +
                                  (component.label.marginRight + 15) +
                                  'px; margin-left: ' +
                                  (component.label.marginLeft + 15) +
                                  'px; letter-spacing: .0892857143em;'
                              "
                            >
                              <v-row no-gutters>
                                <v-col>
                                  <view-text
                                    :settings="component.label"
                                    :value="currentUser.balance | number"
                                  ></view-text>
                                </v-col>
                                <v-col>
                                  <view-text
                                    :settings="component.label"
                                    :value="currentProgram.points.label"
                                  ></view-text>
                                </v-col>
                              </v-row>
                            </v-btn>

                            <!-- Cart -->
                            <v-btn
                              v-if="currentProgram.rewards.cart"
                              text
                              @click="handleShoppingCart()"
                            >
                              <v-icon
                                :size="component.label.size * 1.5"
                                left
                                :color="component.label.color"
                                :style="
                                  'margin-left: ' +
                                    component.label.marginLeft +
                                    'px; '
                                "
                              >
                                shopping_cart
                              </v-icon>
                              <v-badge
                                :value="cartHasItems"
                                :color="
                                  this.$store.getters.currentProgram.webTheme
                                    .colors.primary.hex
                                "
                                :content="cartCount"
                              >
                                <view-text
                                  :settings="component.label"
                                  value="Cart"
                                  :style="
                                    'margin-left: -' +
                                      component.label.marginLeft +
                                      'px !important; '
                                  "
                                ></view-text>
                              </v-badge>
                            </v-btn>

                            <!-- User -->
                            <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn text v-bind="attrs" v-on="on">
                                  <v-icon
                                    :size="component.label.size * 1.5"
                                    left
                                    :color="component.label.color"
                                    :style="
                                      'margin-left: ' +
                                        component.label.marginLeft +
                                        'px; '
                                    "
                                  >
                                    person
                                  </v-icon>
                                  <view-text
                                    :settings="component.label"
                                    :value="name"
                                    :style="
                                      'margin-left: -' +
                                        component.label.marginLeft +
                                        'px !important; '
                                    "
                                  ></view-text>
                                </v-btn>
                              </template>
                              <v-list :class="classes" :style="gradient">
                                <v-list-item @click="handleProfile()">
                                  <v-icon
                                    size="24"
                                    left
                                    :color="
                                      component.dropdown.dropdownLabel.color
                                    "
                                  >
                                    person
                                  </v-icon>
                                  <view-text
                                    :settings="component.dropdown.dropdownLabel"
                                    value="Profile"
                                  ></view-text>
                                </v-list-item>

                                <v-list-item
                                  v-if="currentProgram.rewards.cart"
                                  @click="handleMemberPointsTable()"
                                >
                                  <v-icon
                                    size="24"
                                    left
                                    :color="
                                      component.dropdown.dropdownLabel.color
                                    "
                                  >
                                    stars
                                  </v-icon>
                                  <view-text
                                    :settings="component.dropdown.dropdownLabel"
                                    value="Points"
                                  ></view-text>
                                </v-list-item>

                                <v-list-item
                                  v-if="currentProgram.rewards.cart"
                                  @click="handleMemberOrdersTable()"
                                >
                                  <v-icon
                                    size="24"
                                    left
                                    :color="
                                      component.dropdown.dropdownLabel.color
                                    "
                                  >
                                    list
                                  </v-icon>
                                  <view-text
                                    :settings="component.dropdown.dropdownLabel"
                                    value="Orders"
                                  ></view-text>
                                </v-list-item>

                                <v-list-item @click="signOut()">
                                  <v-icon
                                    size="24"
                                    left
                                    :color="
                                      component.dropdown.dropdownLabel.color
                                    "
                                  >
                                    exit_to_app
                                  </v-icon>
                                  <view-text
                                    :settings="component.dropdown.dropdownLabel"
                                    value="Sign Out"
                                  ></view-text>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                            <!-- Translation -->
                            <v-menu v-if="!hideLanguageSelector" offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn text v-bind="attrs" v-on="on">
                                  <v-icon
                                    :size="component.label.size * 1.5"
                                    left
                                    :color="component.label.color"
                                    :style="
                                      'margin-left: ' +
                                        component.label.marginLeft +
                                        'px; '
                                    "
                                  >
                                    language
                                  </v-icon>
                                  <view-text
                                    :settings="component.label"
                                    :value="locale"
                                    :style="
                                      'margin-left: -' +
                                        component.label.marginLeft +
                                        'px !important; '
                                    "
                                  ></view-text>
                                </v-btn>
                              </template>
                              <v-list :class="classes" :style="gradient">
                                <v-list-item
                                  v-for="(item, i) in translations"
                                  :key="'c' + i"
                                  style="cursor: pointer"
                                  @click="changeLocale(item.title)"
                                >
                                  <view-text
                                    :settings="component.dropdown.dropdownLabel"
                                    :value="item.title"
                                  ></view-text>
                                </v-list-item>
                              </v-list>
                            </v-menu>

                            <!-- Tier -->
                            <v-btn
                              v-for="(tier, i) in membersTiers"
                              :key="('d', i)"
                              class="mr-0"
                              :height="component.tier.button.height"
                              :color="component.tier.button.color.hexa"
                              :outlined="component.tier.button.outlined"
                              elevation="0"
                              :style="
                                'margin-right: ' +
                                  (component.label.marginRight + 15) +
                                  'px; margin-left: ' +
                                  (component.label.marginLeft + 15) +
                                  'px; letter-spacing: .0892857143em;'
                              "
                            >
                              <v-row no-gutters align="center">
                                <v-img
                                  v-if="tier.image.mediumUrl"
                                  :src="tier.image.mediumUrl"
                                  :max-height="component.tier.imageSize"
                                  :max-width="component.tier.imageSize"
                                  contain
                                  class="mr-1"
                                ></v-img>

                                <view-text
                                  :settings="component.tier.tierLabel"
                                  :value="tier.tier"
                                ></view-text>
                              </v-row>
                            </v-btn>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <img
                  v-if="
                    component.logo.align === 'right' && component.logo.image
                  "
                  :src="component.logo.image"
                  :width="component.logo.width"
                  class="ml-4"
                />
              </template>
            </v-app-bar>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>

    <!-- Mobile Side Drawer -->
    <v-navigation-drawer
      :style="'background-color: ' + component.mobile.color.hexa + ';'"
      permanent
      app
      v-if="this.siteDrawer"
    >
      <v-app-bar-nav-icon
        @click="handleSiteDrawer()"
        class="ml-3 mt-2"
        :color="this.$store.getters.currentProgram.webTheme.colors.primary.hex"
      ></v-app-bar-nav-icon>
      <view-logo
        v-if="
          component.mobileLogo.placement === 'top' && component.mobileLogo.image
        "
        :settings="component.mobileLogo"
      />

      <!-- User -->
      <v-row justify="center" class="mt-1 mb-0">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">
              <view-text
                :settings="component.label"
                :value="name"
                :style="
                  'margin-left: -' +
                    component.label.marginLeft +
                    'px !important; '
                "
              ></view-text>
            </v-btn>
          </template>
          <v-list :class="classes" :style="gradient">
            <v-list-item @click="handleProfile()">
              <v-icon
                size="24"
                left
                :color="component.dropdown.dropdownLabel.color"
              >
                person
              </v-icon>
              <view-text
                :settings="component.dropdown.dropdownLabel"
                value="Profile"
              ></view-text>
            </v-list-item>
            <v-list-item @click="signOut()">
              <v-icon
                size="24"
                left
                :color="component.dropdown.dropdownLabel.color"
              >
                exit_to_app
              </v-icon>
              <view-text
                :settings="component.dropdown.dropdownLabel"
                value="Sign Out"
              ></view-text>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>

      <!-- Points -->
      <v-row justify="center" class="mt-n1 mb-2">
        <v-btn
          v-if="currentProgram.rewards.active"
          text
          @click="handleMemberPointsTable()"
        >
          <view-text
            :settings="component.label"
            :value="currentUser.balance | number"
          ></view-text>
          <view-text
            :settings="component.label"
            :value="currentProgram.points.label"
          ></view-text>
        </v-btn>
      </v-row>

      <!-- Tier -->
      <v-row justify="center" class="mt-1 mb-4">
        <v-btn
          v-for="(tier, i) in membersTiers"
          :key="'e' + i"
          class="mr-0"
          :height="component.tier.button.height"
          :color="component.tier.button.color.hexa"
          :outlined="component.tier.button.outlined"
          elevation="0"
        >
          <v-img
            v-if="tier.image.mediumUrl"
            :src="tier.image.mediumUrl"
            :max-height="component.tier.imageSize"
            :max-width="component.tier.imageSize"
            contain
          ></v-img>

          <view-text
            :settings="component.tier.tierLabel"
            :value="tier.tier"
          ></view-text>
        </v-btn>
      </v-row>

      <v-divider class="ml-4 mr-4 mb-1 mt-2" color="grey"></v-divider>

      <!-- Mobile menu items -->
      <v-list dense nav>
        <v-list-item
          v-for="(menu, i) in primaryMenus"
          :key="'f' + i"
          :to="menu.to"
        >
          <v-list-item-content>
            <view-text
              :settings="component.mobileLabel"
              :value="menu.title"
            ></view-text>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider class="ml-4 mr-4" color="grey"></v-divider>

      <v-list dense nav>
        <v-list-item
          v-for="(menu, i) in secondaryMenus"
          :key="'g' + i"
          :to="menu.to"
        >
          <v-list-item-content>
            <view-text
              :settings="component.mobileLabel"
              :value="menu.title"
            ></view-text>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider
        v-if="secondaryMenus.length > 0"
        class="ml-4 mr-4 mb-6"
        color="grey"
      ></v-divider>

      <v-row justify="center" class="mt-2 mb-4">
        <!-- Cart -->
        <v-btn
          v-if="currentProgram.rewards.cart"
          text
          @click="handleShoppingCart()"
        >
          <v-icon
            :size="component.label.size * 1.5"
            left
            :color="component.label.color"
            :style="'margin-left: ' + component.label.marginLeft + 'px; '"
          >
            shopping_cart
          </v-icon>
          <v-badge :color="webTheme.colors.primary.hexa" content="2">
            <view-text
              :settings="component.label"
              value="Cart"
              :style="
                'margin-left: -' +
                  component.label.marginLeft +
                  'px !important; '
              "
            ></view-text>
          </v-badge>
        </v-btn>
      </v-row>

      <v-row v-if="!hideLanguageSelector" justify="center" class="mt-2 mb-2">
        <!-- Translation -->
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">
              <v-icon
                :size="component.label.size * 1.5"
                left
                :color="component.label.color"
                :style="'margin-left: ' + component.label.marginLeft + 'px; '"
              >
                language
              </v-icon>
              <view-text
                :settings="component.label"
                :value="locale"
                :style="
                  'margin-left: -' +
                    component.label.marginLeft +
                    'px !important; '
                "
              ></view-text>
            </v-btn>
          </template>
          <v-list :class="classes" :style="gradient">
            <v-list-item
              v-for="(item, index) in translations"
              :key="index"
              style="cursor: pointer"
              @click="changeLocale(item.title)"
            >
              <view-text
                :settings="component.dropdown.dropdownLabel"
                :value="item.title"
              ></view-text>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>

      <view-logo
        v-if="
          component.mobileLogo.placement === 'bottom' &&
            component.mobileLogo.image
        "
        :settings="component.mobileLogo"
      />
    </v-navigation-drawer>

    <MemberPointsTable
      v-if="openMemberPointsTable"
      :member="currentUser"
      :memberId="currentUser._id"
      :open="openMemberPointsTable"
      @onClose="handleCloseMemberPointsTable"
      :component="component"
    />

    <MemberOrdersTable
      v-if="openMemberOrdersTable"
      :member="currentUser"
      :memberId="currentUser._id"
      :open="openMemberOrdersTable"
      @onClose="handleCloseMemberOrdersTable"
      :component="component"
    />

    <ShoppingCart
      v-if="openShoppingCart"
      :member="currentUser"
      :open="openShoppingCart"
      @onClose="handleCloseShoppingCart"
      :component="component"
    />
  </div>
</template>

<script>
import ViewText from "../Shared/Text.vue";
import ViewLogo from "../Shared/Logo.vue";
import MemberPointsTable from "../Shared/Points/MemberPointsTable.vue";
import MemberOrdersTable from "../Shared/Points/MemberOrdersTable.vue";
import ShoppingCart from "../Shared/Cart/ShoppingCart.vue";

export default {
  components: {
    ViewText,
    ViewLogo,
    MemberPointsTable,
    MemberOrdersTable,
    ShoppingCart
  },
  props: ["component", "index"],
  async created() {
    this.$store.dispatch("loadTranslations");
    if (this.currentUser) {
      this.$store.dispatch("getPrimaryMenus", this.currentUser);
      this.$store.dispatch("getSecondaryMenus", this.currentUser);
      this.$store.dispatch("loadTiers");
      this.$store.dispatch("loadMemberQualifiedTierData");
      await this.$store.dispatch("loadCompanyQualifiedTierData");
    }
  },
  data: function() {
    return {
      openMemberPointsTable: false,
      openMemberOrdersTable: false,
      openShoppingCart: false
    };
  },

  computed: {
    defaultLocale() {
      return this.currentProgram.translate.locale;
    },
    defaultMemberTier() {
      return this.$store.getters.defaultMemberTier;
    },
    defaultCompanyTier() {
      return this.$store.getters.defaultCompanyTier;
    },
    hideLanguageSelector() {
      return this.currentProgram.translate.hide || false;
    },
    locale() {
      return this.$i18n.locale;
    },
    cart() {
      return this.$store.getters.cart;
    },
    primaryMenuItems() {
      return this.$store.getters.primaryMenus || [];
    },
    primaryMenus() {
      const menus = this.primaryMenuItems.map(el => {
        return {
          to:
            el.contentType === "Blog"
              ? `/articles/${el.content}`
              : el.contentType === "Reward Catalogue"
              ? `/rewards/${el.content}`
              : el.contentType === "Offers"
              ? `/offers/${el.content}`
              : el.contentType === "System Page"
              ? `/${el.content.toLowerCase()}`
              : `/page/${el.content}`,
          title: el.title,
          icon: ""
        };
      });
      return menus || [];
    },
    cartHasItems() {
      return this.cart.length ? true : false;
    },
    cartCount() {
      return this.cart.length;
    },
    secondaryMenuItems() {
      return this.$store.getters.secondaryMenus;
    },
    secondaryMenus() {
      return this.secondaryMenuItems.map(el => {
        return {
          to:
            el.contentType === "Blog"
              ? `/articles/${el.content}`
              : el.contentType === "Reward Catalogue"
              ? `/rewards/${el.content}`
              : el.contentType === "System Page"
              ? `/${el.content}`
              : `/page/${el.content}`,
          title: el.title,
          icon: ""
        };
      });
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
    name() {
      const first = this.$store.getters.currentUser.firstname;
      const last = this.$store.getters.currentUser.lastname;
      return first + " " + last;
    },
    currentProgram() {
      return this.$store.getters.currentProgram;
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme;
    },
    siteDrawer() {
      return this.$store.getters.siteDrawer;
    },
    translations() {
      var arr = this.$store.state.translate.translations.filter(
        el => el.status === "Active"
      );
      if (arr.length > 0) {
        if (arr.find(el => el.title === "EN - English (English) ")) {
          return arr;
        } else {
          var en = { title: "EN - English (English) " };
          var result = [en, ...arr];
          return result;
        }
      } else {
        return [];
      }
    },
    barClass() {
      if (this.component.settings.overlap === true) {
        return (
          "margin-bottom: -" +
          this.webTheme.shared.menuHeight +
          "px; z-index: 1; "
        );
      } else {
        return "margin-bottom: 0px; z-index: 1";
      }
    },
    grid() {
      return this.$store.getters.grid;
    },
    classes() {
      if (this.component.cart.fullscreen === true) return "rounded-0";
      return this.component.cart.rounded;
    },
    gradient() {
      if (this.component.dropdown.color.type === "gradient") {
        var color = this.component.dropdown.color;
        var gradient =
          "background: linear-gradient(" +
          color.direction +
          ", rgba(" +
          color.gradientFrom.rgba.r +
          ", " +
          color.gradientFrom.rgba.g +
          ", " +
          color.gradientFrom.rgba.b +
          ", " +
          color.gradientFrom.rgba.a +
          ") " +
          ", rgba(" +
          color.gradientTo.rgba.r +
          ", " +
          color.gradientTo.rgba.g +
          ", " +
          color.gradientTo.rgba.b +
          ", " +
          color.gradientTo.rgba.a +
          ")" +
          color.gradientHeight +
          "%)";
        return gradient;
        // return 'background: linear-gradient(to top, rgba(0, 0, 255, 100), rgba(255, 0, 0, 100) 50%)'
      } else {
        return (
          "background: " + this.component.dropdown.color.solidColor.hexa + ";"
        );
      }
    },
    tiers() {
      return this.$store.state.tier.tiers;
    },
    memberQualifiedTierData() {
      const arr = this.$store.getters.memberQualifiedTierData;
      const defaultTier = this.defaultMemberTier;
      const qualified = arr
        .filter(el => el.accountId === this.currentUser.accountKey)
        .map(({ tier, image }) => ({ tier, image }));
      return qualified.length > 0 ? qualified : defaultTier;
    },
    companyQualifiedTierData() {
      if (this.currentUser.companies.length !== 0) {
        const memberId = this.currentUser._id;
        const arr = this.$store.getters.companyQualifiedTierData;
        const defaultTier = this.defaultCompanyTier;
        //console.log("defaultTier)", defaultTier);
        const qualified = arr.filter(obj =>
          obj.companyMembers.some(id => id === memberId)
        );
        //console.log("co qual", qualified);
        return qualified.length > 0 ? qualified : defaultTier;
      }
      return [];
    },
    membersTiers() {
      return this.memberQualifiedTierData.concat(this.companyQualifiedTierData);
    }
  },
  methods: {
    changeLocale(locale) {
      var val = locale.substr(0, 2).toLowerCase();
      this.$i18n.locale = val;
      //console.log(this.$i18n.locale);
      // this.locale = val;
    },
    handleSiteDrawer() {
      if (this.siteDrawer === true) {
        this.$store.dispatch("setSiteDrawer", false);
      } else {
        this.$store.dispatch("setSiteDrawer", true);
      }
    },
    signOut() {
      this.$store.dispatch("logout");
      this.$router.push("/signin");
    },
    handleProfile() {
      this.$router.push("/profile");
    },
    handleShoppingCart() {
      this.openShoppingCart = false;
      this.openShoppingCart = true;
    },
    handleCloseShoppingCart() {
      this.openShoppingCart = false;
    },
    handleMemberPointsTable() {
      this.$store.dispatch("loadTransactions", this.currentUser._id);
      this.openMemberPointsTable = true;
    },
    handleCloseMemberPointsTable() {
      this.openMemberPointsTable = false;
    },
    handleMemberOrdersTable() {
      this.$store.dispatch("loadMemberOrders", this.currentUser._id);
      this.openMemberOrdersTable = true;
    },
    handleCloseMemberOrdersTable() {
      this.openMemberOrdersTable = false;
    }
  }
};
</script>

<style scoped>
.v-btn.menu-text-scale {
  font-size: 0.875rem !important;
}
</style>

<template>
  <div>
    <v-dialog v-model="open" persistent width="500px">
      <v-card :class="classes" :style="styles + ';' + gradient">
        <v-img
          max-height="300"
          :src="reward.image.mediumUrl"
          :lazy-src="reward.image.mediumUrl"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <v-card-title>
          <view-text
            :settings="component.history.points"
            :value="reward.title"
          ></view-text>
        </v-card-title>
        <v-card-text>
          <v-row no-gutters class="mb-5">
            <v-col>
              <v-row no-gutters v-if="reward.sku">
                <v-col>
                  <view-text
                    :settings="rewardStyle"
                    value="Product Code"
                    suffix=": "
                  ></view-text>
                  <view-text
                    :settings="rewardStyle"
                    :value="reward.sku"
                  ></view-text>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <view-text
                  :settings="rewardStyle"
                  value="Points"
                  suffix=": "
                ></view-text>
                <view-text
                  :settings="rewardStyle"
                  :value="reward.points | number"
                ></view-text>
              </v-row>
            </v-col>
          </v-row>
          <view-text :settings="rewardStyle" :value="reward.body"></view-text>
        </v-card-text>
        <v-card-actions>
          <v-row justify="end">
            <v-btn
              elevation="0"
              :color="component.history.button.color.hex"
              :dark="!component.history.button.dark"
              @click="handleClose()"
            >
              {{ $t("CLOSE") }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ViewText from "../Text.vue";
export default {
  props: ["reward", "open", "component"],
  components: {
    ViewText
  },
  data: () => ({}),
  computed: {
    classes() {
      if (this.component.history.fullscreen === true) return "rounded-0";
      return this.component.history.rounded;
    },
    styles() {
      var styles =
        "border: " +
        this.component.history.borderSize +
        "px solid " +
        ";" +
        "padding-bottom: 9px ;";
      this.component.history.borderColor.hexa + "; ";
      return styles;
    },
    gradient() {
      if (this.component.history.color.type === "gradient") {
        var color = this.component.history.color;
        var gradient =
          "background: linear-gradient(" +
          color.direction +
          ", rgba(" +
          color.gradientFrom.rgba.r +
          ", " +
          color.gradientFrom.rgba.g +
          ", " +
          color.gradientFrom.rgba.b +
          ", " +
          color.gradientFrom.rgba.a +
          ") " +
          ", rgba(" +
          color.gradientTo.rgba.r +
          ", " +
          color.gradientTo.rgba.g +
          ", " +
          color.gradientTo.rgba.b +
          ", " +
          color.gradientTo.rgba.a +
          ")" +
          color.gradientHeight +
          "%)";
        return gradient;
        // return 'background: linear-gradient(to top, rgba(0, 0, 255, 100), rgba(255, 0, 0, 100) 50%)'
      } else {
        return (
          "background: " + this.component.history.color.solidColor.hexa + ";"
        );
      }
    },
    rewardStyle() {
      return {
        ...this.component.history.subheading,
        floatText: "text-left"
      };
    }
  },
  methods: {
    handleClose() {
      this.$emit("onClose");
    }
  }
};
</script>

<template>          
  <v-container
    fluid
    tile
    class="pa-0"
    :style="'background-color: ' + this.webTheme.colors.canvas.hexa + ';'"
  >
    <v-row no-gutters justify="center">
      <v-col xs="12" :sm="component.settings.cols">
        <v-sheet
          tile
          :color="this.webTheme.colors.content.hexa"
          :style="block"
        >
          <v-row no-gutters justify="center">
            <v-col>
              <view-text :settings="component.title"></view-text>
            </v-col>
          </v-row>
          <v-card
            tile
            elevation="0"
            color="transparent"
            >
            <v-row
              v-if="component.card.group.slider === 'slide'"
              no-gutters
              :justify="component.card.group.justify"
            >
              <v-col :lg="component.card.group.cols">
                <v-card
                  tile
                  elevation="0"
                  :class="grid.inner"
                  background-color="transparent"
                >
                  <v-slide-group
                    :dark="component.card.group.dark"
                    show-arrows="always"
                  >
                    <v-slide-item
                      v-for="(card, i) in progressData"
                      :key="i"
                    >
                      <v-card
                        :class="classes"
                        :style="styles + ';' + gradient"
                        :height="component.card.height"
                        :width="component.card.width"
                        :elevation="component.card.elevation"
                        @click="card.cardType === 'leaderboard'
                          ? openLeaderboardPopUp(card)
                          : openTablePopUp(card)"
                      >
                      <template v-if="!component.card.icon.hide">
                        <div>
                          <img
                            v-if="card.cardType === 'leaderboard'"
                            :style="svgStyle"
                            :src="'/img/icons/' + card.iconToRender.svgFile"
                            :height="component.card.icon.size"
                          />
                          <img
                            v-else
                            :style="svgStyle"
                            :src="'/img/icons/006-objective.svg'"
                            :height="component.card.icon.size"
                          />
                        </div>
                      </template>
                        <v-row no-gutters>
                          <v-col>
                            <view-text v-if="card.prefix" :settings="component.prefix" :value="card.prefix" />
                            <view-text v-if="card.rankValue" :settings="component.result" :value="ordinal(card.rankValue)" />
                            <view-text v-if="card.homeCardValue" :settings="component.result" :value="card.homeCardValue" />
                            <view-text v-if="card.suffix" :settings="component.suffix" :value="card.suffix" />
                            <view-text :settings="component.displayTitle" :value="card.displayTitle" />
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-slide-item>
                  </v-slide-group>
                </v-card>
              </v-col>
            </v-row>
            <v-row 
              v-else
              no-gutters
              :justify="component.card.group.justify"
            >
              <v-col :lg="component.card.group.cols">
                <v-card
                  tile
                  elevation="0"
                  :class="grid.inner"
                >
                  <v-row
                    class="mx-2 mx-lg-0"
                    :justify="component.card.group.justify"
                    
                  >
                  <v-card
                    v-for="(card, i) in progressData"
                    :key="i"
                    :class="classes"
                    :style="styles + ';' + gradient"
                    :height="component.card.height"
                    :width="component.card.width"
                    :elevation="component.card.elevation"
                    @click="card.cardType === 'leaderboard'
                      ? openLeaderboardPopUp(card)
                      : openTablePopUp(card)"
                  >
                      <template v-if="!component.card.icon.hide">
                        <div>
                          <img
                            v-if="card.cardType === 'leaderboard'"
                            :style="svgStyle"
                            :src="'/img/icons/' + card.iconToRender.svgFile"
                            :height="component.card.icon.size"
                          />
                          <img
                            v-else
                            :style="svgStyle"
                            :src="'/img/icons/006-objective.svg'"
                            :height="component.card.icon.size"
                          />
                        </div>
                      </template>
                        <v-row no-gutters>
                          <v-col>
                            <view-text v-if="card.prefix" :settings="component.prefix" :value="card.prefix" />
                            <view-text v-if="card.rankValue" :settings="component.result" :value="ordinal(card.rankValue)" />
                            <view-text v-if="card.homeCardValue" :settings="component.result" :value="card.homeCardValue" />
                            <view-text v-if="card.suffix" :settings="component.suffix" :value="card.suffix" />
                            <view-text :settings="component.displayTitle" :value="card.displayTitle" />
                          </v-col>
                        </v-row>

                      </v-card>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
    <leaderboard-dialog
      :is-visible="showLeaderboardData"
      :on-close="closeLeaderboardPopUp"
      :progress-data="activeLeaderboardData"
      :component="component"
      :rankValue="ordinal(activeLeaderboardData.rankValue)"
    ></leaderboard-dialog>
    <table-dialog
      :is-visible="showTableData"
      :on-close="closeTablePopUp"
      :progress-data="activeTableData"
      :component="component"
    ></table-dialog>
  </v-container>

</template>

<script>
import ViewText from '../Text.vue'
import LeaderboardDialog from './LeaderboardDialog.vue'
import TableDialog from './TableDialog.vue'
// import { DEMO_USER } from '@/constants/webtheme/demouser'

 export default {
  components: {
    ViewText,
    LeaderboardDialog,
    TableDialog
  },
  props: [ 'component', 'index'],
  created () {
    // this.$store.dispatch('getSampleProgressData', DEMO_USER)
  },
  data: function () {
  return {
    showLeaderboardData: false,
    activeLeaderboardData: {},
    showTableData: false,
    activeTableData: {},
  }
  },
  computed: {
    svgStyle()  {
      var opacity = 10/this.component.card.icon.opacity
      var marginLeft = this.component.card.icon.marginLeft
      var marginTop = this.component.card.icon.marginTop
      return 'opacity: ' + opacity + ' !important;' 
        + 'margin-left: ' + marginLeft + 'px;'
        + 'margin-top: ' + marginTop + 'px;'
        + 'position: absolute;'
    },
    classes () {
      var classes = this.component.card.rounded
      return classes
    },
    grid() {
      return this.$store.getters.grid
    },
    block() {
      var padding = this.component.block.padding
      return this.grid.block + 'px ;' +
      'padding-left: ' + padding.left + 'px ;' +
      'padding-right: ' + padding.right + 'px ;' +
      'padding-top: ' + padding.top + 'px ;' +
      'padding-bottom: ' + padding.bottom + 'px ;'
    },
    gradient() {
      if (this.component.card.color.type === 'gradient') {
        var color = this.component.card.color
        var gradient = 
        'background: linear-gradient(' +
        color.direction + ', rgba(' +
        color.gradientFrom.rgba.r + ', ' +
        color.gradientFrom.rgba.g + ', ' +
        color.gradientFrom.rgba.b + ', ' +
        color.gradientFrom.rgba.a + ') ' + 
        ', rgba(' +
        color.gradientTo.rgba.r + ', ' +
        color.gradientTo.rgba.g + ', ' +
        color.gradientTo.rgba.b + ', ' +
        color.gradientTo.rgba.a + ')' + color.gradientHeight + '%)'
        return gradient
        // return 'background: linear-gradient(to top, rgba(0, 0, 255, 100), rgba(255, 0, 0, 100) 50%)'
      } else {
        return 'background: ' + this.component.card.color.solidColor.hexa + ';'
      }
    },
    styles () {
      var styles = 'margin-bottom: ' + this.component.card.margin.bottom + 'px ;' +
        'margin-top: ' + this.component.card.margin.top + 'px ;' +
        'margin-left: ' + this.component.card.margin.left + 'px ;' +
        'margin-right: ' + this.component.card.margin.right + 'px ;' +
        'padding-bottom: ' + this.component.card.padding.bottom + 'px ;' +
        'padding-top: ' + this.component.card.padding.top + 'px ;' +
        'padding-left: ' + this.component.card.padding.left + 'px ;' +
        'padding-right: ' + this.component.card.padding.right + 'px ;' +
        'border: ' + this.component.card.borderSize + 'px solid ' +
        this.component.card.borderColor.hexa + '; '
      return styles
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme
    },
    leaderBoardData () {
      return this.$store.getters.leaderBoardData
    },
    tableData () {
      return this.$store.getters.tableData
    },
    progressData() {
      return this.leaderBoardData.concat(this.tableData)
    }
  },
  methods: {
    openLeaderboardPopUp (value) {
      this.activeLeaderboardData = value
      this.showLeaderboardData = true
    },
    closeLeaderboardPopUp () {
      this.showLeaderboardData = false
    },
    openTablePopUp (value) {
      this.activeTableData = value
      this.showTableData = true
    },
    closeTablePopUp () {
      this.showTableData = false
    },
    ordinal (n) {
      var s = ['th', 'st', 'nd', 'rd']
      var v = n % 100
      return n + (s[(v - 20) % 10] || s[v] || s[0])
    },
  }
}
</script>

<style scoped>
.svg-opacity {
  opacity: 0.5 !important;
}
</style>


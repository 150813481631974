<template>
  <div>
    <v-dialog
      v-model="open"
      :width="component.history.width"
      :fullscreen="component.history.fullscreen"
      persistent
      scrollable
    >
      <v-card :class="classes" :style="styles + ';' + gradient">
        <v-card-text>
          <v-row no-gutters justify="space-between">
            <v-col>
              <view-text
                :settings="component.history.historyTitle"
                value="Order"
              ></view-text>
            </v-col>
            <v-col cols="auto" class="pr-2">
              <view-text
                :settings="component.history.subheading"
                value="Order Points Value"
                suffix=": "
              ></view-text>
            </v-col>
            <v-col cols="auto">
              <view-text
                :settings="component.history.subheading"
                :value="order.cartValue | number"
              ></view-text>
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="mb-3">
            <v-col sm="3" v-if="options.search">
              <v-text-field
                v-model="search"
                solo-inverted
                dense
                flat
                hide-details
                :label="$t('Search')"
                prepend-inner-icon="search"
                class="mr-4"
              ></v-text-field>
            </v-col>

            <v-col sm="3" v-if="options.filter">
              <v-select
                solo-inverted
                dense
                flat
                hide-details
                label="Filter"
                prepend-inner-icon="filter_list"
                class="mr-4"
                multiple
                :items="typeList"
                v-model="filteredType"
              ></v-select>
            </v-col>

            <v-col>
              <v-btn
                @click="handleOpenImportDialog()"
                v-if="options.upload"
                class="primary--text"
                text
                :disabled="demo"
                >Upload</v-btn
              >
              <v-btn
                v-if="options.download"
                class="primary--text"
                text
                :disabled="demo"
                >Download</v-btn
              >
              <v-btn v-if="options.more" class="primary--text" text>More</v-btn>
            </v-col>

            <v-col sm="auto">
              <v-btn
                v-if="options.new"
                @click="handleNew()"
                :color="
                  this.$store.getters.currentProgram.webTheme.colors.primary.hex
                "
                elevation="0"
                class="mr-n1"
              >
                <v-icon left>add</v-icon>new points transaction
              </v-btn>
            </v-col>
          </v-row>

          <v-card color="transparent" elevation="0" :style="detailTableStyles">
            <v-data-table
              :headers="headers"
              :items="orderItems"
              :search="search"
              :options.sync="options"
              :loading="loadingMemberOrdersTable"
              :no-data-text="$t('No transactions')"
              :no-results-text="$t('No matching records found')"
              :footer-props="footerProps"
              :style="cssVars"
              class="table"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  @click="handleEdit(item)"
                  class="mr-2"
                  :color="component.dropdown.dropdownLabel.color"
                  >list_alt</v-icon
                >
              </template>

              <template v-slot:[`item.status`]="{ item }">
                {{ $t(item.status) }}
              </template>

              <template v-slot:[`item.title`]="{ item }">
                <span class="link" @click="handleOpenRewardDetail(item)">{{
                  item.title | truncate(45)
                }}</span>
              </template>

              <template v-slot:[`item.created`]="{ item }">
                {{ item.created | convertTimestamp }}
              </template>

              <template v-slot:[`item.updated`]="{ item }">
                {{ item.updated | convertTimestamp }}
              </template>

              <template v-slot:[`item.each`]="{ item }">
                <v-row justify="end" no-gutters>
                  {{ item.each | number }}
                </v-row>
              </template>

              <template v-slot:[`item.subtotal`]="{ item }">
                <v-row justify="end" no-gutters>
                  {{ item.subtotal | number }}
                </v-row>
              </template>

              <template v-slot:[`item.quantity`]="{ item }">
                <v-row justify="end" no-gutters>
                  {{ item.quantity | number }}
                </v-row>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-row justify="end">
            <v-btn
              elevation="0"
              :color="component.history.button.color.hex"
              :dark="!component.history.button.dark"
              @click="handleClose()"
            >
              {{ $t("CLOSE") }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <RewardDetail
      v-if="dialogRewardOpen"
      :open="dialogRewardOpen"
      :reward="selectedReward"
      @onClose="handleCloseRewardDetail"
      :component="component"
    ></RewardDetail>
  </div>
</template>

<script>
import ViewText from "../Text.vue";
import RewardDetail from "./RewardDetail.vue";

export default {
  created() {},
  props: ["open", "member", "memberId", "component", "order"],
  components: {
    ViewText,
    RewardDetail
  },
  data: () => ({
    closeDialog: false,
    filteredType: [],
    search: "",
    selectedItem: "",
    dialogNewOrEditOpen: false,
    dialogDeleteOpen: false,
    notes: "",
    description: "",
    points: "",
    dialogRewardOpen: false,
    selectedReward: "",

    // Table settings
    options: {
      sortBy: ["created"],
      sortDesc: [true],
      itemsPerPage: 10,
      sortable: true,
      multiSort: true,
      search: true,
      filter: false,
      upload: false,
      download: false,
      more: false,
      new: false
    }
  }),
  computed: {
    headers() {
      return [
        { text: this.$t("Status"), value: "status", align: "left" },
        {
          text: this.$t("Item"),
          value: "title",
          align: "left",
          sortable: false
        },
        { text: this.$t("Date"), value: "created", align: "left" },
        { text: this.$t("Updated"), value: "updated", align: "left" },
        {
          text: this.$t("Quantity"),
          value: "quantity",
          align: "right",
          sortable: false
        },
        {
          text: this.$t("Each"),
          value: "each",
          align: "right",
          sortable: false
        },
        {
          text: this.$t("Subtotal"),
          value: "subtotal",
          align: "right",
          sortable: false
        }
      ];
    },
    footerProps() {
      return {
        "items-per-page-text": this.$t("Rows per page"),
        "items-per-page-options": [5, 10, 25, 50, 100]
      };
    },
    memberOrders() {
      return this.$store.getters.memberOrders;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    loadingMemberOrdersTable() {
      return this.$store.getters.loadingMemberOrdersTable;
    },
    orderItems() {
      return this.order.items;
    },
    cssVars() {
      return {
        "--header-text-color": this.component.history.content.heading.color,
        "--header-text-size":
          this.component.history.content.heading.size + "px",
        "--header-text-style": this.component.history.content.heading.style,
        "--header-text-weight": this.component.history.content.heading.weight,
        "--header-line-height": this.component.history.content.heading
          .lineHeight,
        "--header-font": this.component.history.content.heading.font,
        "--row-text-color": this.component.history.content.data.color,
        "--row-text-size": this.component.history.content.data.size + "px",
        "--row-text-style": this.component.history.content.data.style,
        "--row-text-weight": this.component.history.content.data.weight,
        "--row-line-height": this.component.history.content.data.lineHeight,
        "--row-font": this.component.history.content.data.font,
        "--text-align": this.component.history.content.align,
        "--row-border-bottom": this.rowBorders,
        "--row-hover-color": this.component.history.rowHoverColor.hexa,
        "--current-page-color": this.component.history.title.color
      };
    },
    cssVarsTransactions() {
      return {
        "--header-text-color": this.component.history.content.heading.color,
        "--header-text-size":
          this.component.history.content.heading.size + "px",
        "--header-text-style": this.component.history.content.heading.style,
        "--header-text-weight": this.component.history.content.heading.weight,
        "--header-line-height": this.component.history.content.heading
          .lineHeight,
        "--header-font": this.component.history.content.heading.font,
        "--row-text-color": this.component.history.content.data.color,
        "--row-text-size": this.component.history.content.data.size + "px",
        "--row-text-style": this.component.history.content.data.style,
        "--row-text-weight": this.component.history.content.data.weight,
        "--row-line-height": this.component.history.content.data.lineHeight,
        "--row-font": this.component.history.content.data.font,
        "--text-align": "left",
        "--row-border-bottom": this.rowBorders,
        "--row-hover-color": this.component.history.rowHoverColor.hexa,
        "--current-page-color": this.component.history.title.color
      };
    },
    classes() {
      if (this.component.history.fullscreen === true) return "rounded-0";
      return this.component.history.rounded;
    },
    rowBorders() {
      return (
        this.component.cart.rowBorderSize +
        "px" +
        " solid " +
        this.component.cart.rowBorderColor.hexa
      );
    },
    gradient() {
      if (this.component.history.color.type === "gradient") {
        var color = this.component.history.color;
        var gradient =
          "background: linear-gradient(" +
          color.direction +
          ", rgba(" +
          color.gradientFrom.rgba.r +
          ", " +
          color.gradientFrom.rgba.g +
          ", " +
          color.gradientFrom.rgba.b +
          ", " +
          color.gradientFrom.rgba.a +
          ") " +
          ", rgba(" +
          color.gradientTo.rgba.r +
          ", " +
          color.gradientTo.rgba.g +
          ", " +
          color.gradientTo.rgba.b +
          ", " +
          color.gradientTo.rgba.a +
          ")" +
          color.gradientHeight +
          "%)";
        return gradient;
        // return 'background: linear-gradient(to top, rgba(0, 0, 255, 100), rgba(255, 0, 0, 100) 50%)'
      } else {
        return (
          "background: " + this.component.history.color.solidColor.hexa + ";"
        );
      }
    },
    styles() {
      var styles =
        "padding-bottom: " +
        this.component.history.padding.bottom +
        "px ;" +
        "padding-top: " +
        this.component.history.padding.top +
        "px ;" +
        "padding-left: " +
        this.component.history.padding.left +
        "px ;" +
        "padding-right: " +
        this.component.history.padding.right +
        "px ;" +
        "border: " +
        this.component.history.borderSize +
        "px solid " +
        ";";
      this.component.history.borderColor.hexa + "; ";
      return styles;
    },
    detailTableStyles() {
      var styles =
        "margin-bottom: " +
        this.component.history.detail.margin.bottom +
        "px ;" +
        "margin-top: " +
        this.component.history.detail.margin.top +
        "px ;" +
        "margin-left: " +
        this.component.history.detail.margin.left +
        "px ;" +
        "margin-right: " +
        this.component.history.detail.margin.right +
        "px ;";
      return styles;
    }
  },
  methods: {
    handleClose() {
      this.$emit("onClose");
    },
    orderItemCount(order) {
      const arr = order.filter(el => el.status !== "Cancelled");
      return arr.reduce((acc, val) => {
        return acc + val.quantity;
      }, 0);
    },
    handleViewDialog(item) {
      this.selectedItem = item;
      this.dialogViewOpen = true;
    },
    handleCloseViewDialog() {
      this.selectedItem = "";
      this.dialogViewOpen = false;
    },
    handleOpenRewardDetail(item) {
      this.selectedReward = item;
      this.dialogRewardOpen = true;
    },
    handleCloseRewardDetail() {
      this.selectedReward = "";
      this.dialogRewardOpen = false;
    }
  }
};
</script>

<style>
.v-avatar.av-outline {
  outline: 2px solid white;
}
</style>

<template>
  <v-container
    fluid
    tile
    class="pa-0"
    :style="'background-color: ' + this.webTheme.colors.canvas.hexa + ';'"
  >
    <v-row no-gutters justify="center">
      <v-col xs="12" :sm="component.settings.cols">
        <v-sheet tile :color="this.webTheme.colors.content.hexa" :style="block">
          <v-row no-gutters justify="center">
            <v-col>
              <view-text :settings="component.title"></view-text>
            </v-col>
          </v-row>

          <v-row v-for="(offer, id) in offers" :key="id" no-gutters>
            <v-col>
              <v-row no-gutters>
                <view-text
                  :settings="component.heading"
                  :value="offer.displayTitle"
                ></view-text>
              </v-row>

              <v-row
                v-if="component.card.group.slider === 'slide'"
                no-gutters
                :justify="component.card.group.justify"
              >
                <v-col :lg="component.card.group.cols">
                  <v-card
                    tile
                    elevation="0"
                    :class="grid.inner"
                    background-color="transparent"
                  >
                    <v-slide-group
                      :dark="component.card.group.dark"
                      show-arrows="always"
                    >
                      <v-slide-item
                        v-for="(card, id) in offer.opportunities"
                        :key="id"
                      >
                        <v-card
                          :class="classes"
                          :style="styles + ';' + gradient"
                          :height="component.card.height"
                          :width="component.card.width"
                          :elevation="component.card.elevation"
                          @click="openOfferPopUp(offer, card)"
                        >
                          <template v-if="!component.card.icon.hide">
                            <div>
                              <v-icon
                                :style="svgStyle"
                                :size="component.card.icon.size"
                                >fa-light fa-receipt</v-icon
                              >
                            </div>
                          </template>
                          <v-row no-gutters>
                            <v-col>
                              <view-text
                                :settings="component.opportunityTitle"
                                :value="card.description"
                              ></view-text>
                              <view-text
                                v-if="component.deadline.show"
                                :settings="component.deadline"
                                :value="formatDeadline(card.deadline)"
                              ></view-text>
                              <view-text
                                v-if="component.points.show"
                                :settings="component.points"
                                :value="card.points | number"
                                :suffix="'Points'"
                              ></view-text>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-slide-item>
                    </v-slide-group>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-else no-gutters :justify="component.card.group.justify">
                <v-col :lg="component.card.group.cols">
                  <v-card tile elevation="0" :class="grid.inner">
                    <v-row
                      class="mx-2 mx-lg-0"
                      :justify="component.card.group.justify"
                    >
                      <v-card
                        v-for="card in offer.opportunities"
                        :key="card.id"
                        :class="classes"
                        :style="styles + ';' + gradient"
                        :height="component.card.height"
                        :width="component.card.width"
                        :elevation="component.card.elevation"
                        @click="openOfferPopUp(offer, card)"
                      >
                        <template v-if="!component.card.icon.hide">
                          <div>
                            <v-icon
                              :style="svgStyle"
                              :size="component.card.icon.size"
                              >fa-light fa-receipt</v-icon
                            >
                          </div>
                        </template>
                        <v-row no-gutters>
                          <v-col>
                            <view-text
                              :settings="component.opportunityTitle"
                              :value="card.description"
                            ></view-text>
                            <view-text
                              :settings="component.deadline"
                              :value="formatDeadline(card.deadline)"
                            ></view-text>
                            <view-text
                              :settings="component.points"
                              :value="card.points + ' Points'"
                            ></view-text>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
    <claim-dialog
      :isVisible="showOffer"
      @onClose="closeOfferPopUp"
      :offerData="activeOfferData"
      :opportunity="activeOpportunityData"
      :component="component"
    ></claim-dialog>
  </v-container>
</template>

<script>
import ViewText from "../Text.vue";
import ClaimDialog from "./ClaimDialog.vue";

export default {
  components: {
    ViewText,
    ClaimDialog
  },
  props: ["component", "index"],
  created() {
    // this.$store.dispatch('getSampleOffers')
  },
  data: function() {
    return {
      showOffer: false,
      activeOfferData: {},
      activeOpportunityData: {},
      categories: []
    };
  },
  computed: {
    svgStyle() {
      var opacity = 10 / this.component.card.icon.opacity;
      var marginLeft = this.component.card.icon.marginLeft;
      var marginTop = this.component.card.icon.marginTop;
      return (
        "opacity: " +
        opacity +
        " !important;" +
        "margin-left: " +
        marginLeft +
        "px;" +
        "margin-top: " +
        marginTop +
        "px;" +
        "position: absolute;"
      );
    },
    classes() {
      var classes = this.component.card.rounded;
      return classes;
    },
    grid() {
      return this.$store.getters.grid;
    },
    block() {
      var padding = this.component.block.padding;
      return (
        this.grid.block +
        "px ;" +
        "padding-left: " +
        padding.left +
        "px ;" +
        "padding-right: " +
        padding.right +
        "px ;" +
        "padding-top: " +
        padding.top +
        "px ;" +
        "padding-bottom: " +
        padding.bottom +
        "px ;"
      );
    },
    gradient() {
      if (this.component.card.color.type === "gradient") {
        var color = this.component.card.color;
        var gradient =
          "background: linear-gradient(" +
          color.direction +
          ", rgba(" +
          color.gradientFrom.rgba.r +
          ", " +
          color.gradientFrom.rgba.g +
          ", " +
          color.gradientFrom.rgba.b +
          ", " +
          color.gradientFrom.rgba.a +
          ") " +
          ", rgba(" +
          color.gradientTo.rgba.r +
          ", " +
          color.gradientTo.rgba.g +
          ", " +
          color.gradientTo.rgba.b +
          ", " +
          color.gradientTo.rgba.a +
          ")" +
          color.gradientHeight +
          "%)";
        return gradient;
        // return 'background: linear-gradient(to top, rgba(0, 0, 255, 100), rgba(255, 0, 0, 100) 50%)'
      } else {
        return "background: " + this.component.card.color.solidColor.hexa + ";";
      }
    },
    styles() {
      var styles =
        "margin-bottom: " +
        this.component.card.margin.bottom +
        "px ;" +
        "margin-top: " +
        this.component.card.margin.top +
        "px ;" +
        "margin-left: " +
        this.component.card.margin.left +
        "px ;" +
        "margin-right: " +
        this.component.card.margin.right +
        "px ;" +
        "padding-bottom: " +
        this.component.card.padding.bottom +
        "px ;" +
        "padding-top: " +
        this.component.card.padding.top +
        "px ;" +
        "padding-left: " +
        this.component.card.padding.left +
        "px ;" +
        "padding-right: " +
        this.component.card.padding.right +
        "px ;" +
        "border: " +
        this.component.card.borderSize +
        "px solid " +
        this.component.card.borderColor.hexa +
        "; ";
      return styles;
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme;
    },
    primaryMenus() {
      return this.$store.getters.primaryMenus;
    },
    screenDetail() {
      return (
        this.primaryMenus.find(
          menuDetail => menuDetail.content === this.$route.params.id
        ) || {}
      );
    },
    offers() {
      return this.$store.getters.offerList;
    }
  },
  watch: {
    screenDetail(value) {
      if (
        this.screenDetail.offers &&
        Object.values(this.screenDetail.offers).length > 0
      ) {
        this.categories = Object.values(this.screenDetail.offers);
      }
    }
  },
  mounted() {
    this.categories =
      (this.screenDetail.offers && Object.values(this.screenDetail.offers)) ||
      [];
  },
  methods: {
    formatDeadline(value) {
      const date = new Date(value);
      return (
        "Offer Ends " +
        date.toLocaleDateString(["en-US"], {
          month: "short",
          day: "2-digit",
          year: "numeric"
        })
      );
    },
    openOfferPopUp(offer, card) {
      this.activeOfferData = offer;
      this.activeOpportunityData = card;
      this.showOffer = true;
    },
    closeOfferPopUp() {
      this.activeOfferData = {};
      this.activeOpportunityData = {};
      this.showOffer = false;
    }
  }
};
</script>

<style scoped>
.svg-opacity {
  opacity: 0.5 !important;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",style:('background-color: ' + this.webTheme.colors.canvas.hexa + ';'),attrs:{"fluid":"","tile":""}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"xs":"12","sm":_vm.component.settings.cols}},[_c('v-sheet',{style:(_vm.block),attrs:{"tile":"","color":this.webTheme.colors.content.hexa}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',[_c('view-text',{attrs:{"settings":_vm.component.title}})],1)],1),_vm._l((_vm.categories),function(category,id){return _c('v-row',{key:id,attrs:{"no-gutters":""}},[_c('v-col',[_c('v-row',{attrs:{"no-gutters":""}},[_c('view-text',{attrs:{"settings":_vm.component.category,"value":category.title}})],1),(_vm.component.card.group.slider === 'slide')?_c('v-row',{attrs:{"justify":_vm.component.card.group.justify,"no-gutters":""}},[_c('v-col',{attrs:{"lg":_vm.component.card.group.cols}},[_c('v-card',{class:_vm.grid.inner,attrs:{"tile":"","elevation":"0","background-color":"transparent"}},[_c('v-slide-group',{attrs:{"dark":_vm.component.card.group.dark,"show-arrows":"always"}},_vm._l((_vm.rewards),function(card,i){return _c('v-slide-item',{key:i},[_c('v-card',{class:_vm.classes,style:(_vm.styles + ';' + _vm.gradient),attrs:{"height":_vm.cardHeight,"width":_vm.component.card.width,"elevation":_vm.component.card.elevation},on:{"click":function($event){return _vm.openRewardPopUp(card)}}},[(_vm.component.image.show === true)?_c('v-img',{class:card.radius ? 'radius' : '',attrs:{"height":_vm.component.image.height + 'px',"lazy-src":card.externalImageUrl
                              ? card.externalImageUrl
                              : card.image.mediumUrl,"src":card.externalImageUrl
                              ? card.externalImageUrl
                              : card.image.mediumUrl},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}):_vm._e(),_c('v-card-text',{style:(_vm.cardText)},[(card.title)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('view-text',{attrs:{"settings":_vm.component.heading,"value":card.title}})],1)],1):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('view-text',{attrs:{"settings":_vm.component.points,"value":_vm.formatPoints(card.points) +
                                    ' ' +
                                    _vm.pointsLabel}})],1)],1),(
                              card.body && _vm.component.settings.previewRows > 0
                            )?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('view-text',{attrs:{"settings":_vm.component.body,"value":card.body,"lines":_vm.component.settings.previewRows}})],1)],1):_vm._e()],1)],1)],1)}),1)],1)],1)],1):_c('v-row',{attrs:{"no-gutters":"","justify":_vm.component.card.group.justify}},[_c('v-col',{attrs:{"lg":_vm.component.card.group.cols}},[_c('v-card',{class:_vm.grid.inner,attrs:{"tile":"","elevation":"0"}},[_c('v-row',{staticClass:"mx-2 mx-lg-0",attrs:{"justify":_vm.component.card.group.justify}},_vm._l((category.rewardList),function(card,i){return _c('v-card',{key:i,class:_vm.classes,style:(_vm.styles + ';' + _vm.gradient),attrs:{"height":_vm.component.card.height,"width":_vm.component.card.width,"elevation":_vm.component.card.elevation},on:{"click":function($event){return _vm.openRewardPopUp(card)}}},[(_vm.component.image.show === true)?_c('v-img',{class:card.radius ? 'radius' : '',attrs:{"height":_vm.component.image.height + 'px',"lazy-src":card.externalImageUrl
                            ? card.externalImageUrl
                            : card.image.mediumUrl,"src":card.externalImageUrl
                            ? card.externalImageUrl
                            : card.image.mediumUrl},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}):_vm._e(),_c('div',[(card.title)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('view-text',{attrs:{"settings":_vm.component.heading,"value":card.title}})],1)],1):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('view-text',{attrs:{"settings":_vm.component.points,"value":_vm.formatPoints(card.points) + ' ' + _vm.pointsLabel}})],1)],1),(card.body)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('view-text',{attrs:{"settings":_vm.component.body,"value":card.body,"lines":_vm.component.settings.previewRows}})],1)],1):_vm._e()],1)],1)}),1)],1)],1)],1)],1)],1)})],2)],1)],1),(_vm.showReward)?_c('reward-dialog',{attrs:{"is-visible":_vm.showReward,"on-close":_vm.closeRewardPopUp,"reward":_vm.activeReward,"component":_vm.component}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
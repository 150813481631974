<template>          
  <v-container
    fluid
    tile
    class="pa-0"
    :style="'background-color: ' + this.webTheme.colors.canvas.hexa"
  >
  <v-row no-gutters justify="center">
    <v-col xs="12" :sm="component.settings.cols">
      <v-sheet
        tile
        :color="this.webTheme.colors.footer.hexa"
        :style="block"
      >
        <v-row 
          no-gutters 
          :justify="component.group.justify"
        >
          <v-col sm="12" :lg="component.block.cols">
            <v-card
              tile
              elevation="0"
              :class="grid.inner"
            >
              <v-row no-gutters>
                <v-col>
                  <v-row
                    v-for="(menu, footId) in primaryMenus"
                    :key="footId"
                  >
                    <span
                      class="link"
                      @click="route(menu.to)"
                    >
                      <view-text :settings="component.menu" :value="menu.title"></view-text>
                    </span>
                  </v-row>
                </v-col>
                <v-col>
                  <v-row
                    v-for="(menu, footIdx) in secondaryMenus"
                    :key="footIdx"
                  >
                    <span
                      class="link"
                      @click="route(menu.to)"
                    >
                      <view-text :settings="component.menu" :value="menu.title"></view-text>
                    </span>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
  </v-row>

  </v-container>
</template>

<script>
import ViewText from '../Shared/Text.vue'

 export default {
  components: {
    ViewText
  },
  props: [ 'component', 'index' ],
   created () {
    if (this.currentUser) {
      this.$store.dispatch('getPrimaryMenus', this.currentUser)
      this.$store.dispatch('getSecondaryMenus', this.currentUser)
    }
  },
   data: function () {
    return {
    }
   },
   computed: {
    currentUser () {
      return this.$store.getters.currentUser
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme
    },
    grid() {
      return this.$store.getters.grid
    },
    primaryMenuItems () {
      return this.$store.getters.primaryMenus || []
    },
    primaryMenus () {
      const menus = this.primaryMenuItems.map(el => {
        return {
          'to': el.contentType === 'Blog'
            ? `/articles/${el.content}`
            : el.contentType === 'Reward Catalogue'
              ? `/rewards/${el.content}`
              : el.contentType === 'Offers'
                ? `/offers/${el.content}`
                : el.contentType === 'System Page'
                  ? `/${el.content.toLowerCase()}`
                  : `/page/${el.content}`,
          'title': el.title,
          'icon': ''
        }
      })
      return menus || []
    },
    secondaryMenuItems () {
      return this.$store.getters.secondaryMenus
    },
    secondaryMenus () {
      return this.secondaryMenuItems.map(el => {
        return {
          'to': el.contentType === 'Blog'
            ? `/articles/${el.content}`
            : el.contentType === 'Reward Catalogue'
              ? `/rewards/${el.content}`
              : el.contentType === 'System Page'
                ? `/${el.content}`
                : `/page/${el.content}`,
          'title': el.title,
          'icon': ''
        }
      })
    },
    block() {
      var padding = this.component.block.padding
      return this.grid.block + 'px ;' +
      'padding-left: ' + padding.left + 'px ;' +
      'padding-right: ' + padding.right + 'px ;' +
      'padding-top: ' + padding.top + 'px ;' +
      'padding-bottom: ' + padding.bottom + 'px ;'
    },
  },
  methods: {
    route(val) {
      this.$router.push(val)
    }
  }
}
</script>

<style scoped>
.section-heading {
  margin-left: 50px;
  line-height: 1.5;
  font-size: 16px;
}
.preview-card {
  border: 1px dotted grey !important;
  background-color: transparent !important;
}
</style>


<template>
  <v-container
    fluid
    tile
    class="pa-0"
    :style="'background-color: ' + this.webTheme.colors.canvas.hexa"
  >
    <v-row justify="center" no-gutters>
      <v-col xs="12" :sm="component.settings.cols">
        <v-sheet :height="height" tile :color="component.settings.color.hexa">
          <template v-if="component.settings.mode === 'color'">
            <v-row
              no-gutters
              :justify="component.block.justify"
              :align="component.block.align"
              class="fill-height"
            >
              <v-col xs="12" :sm="component.block.cols">
                <v-col>
                  <v-card
                    tile
                    elevation="0"
                    :class="titleBlock"
                    :style="topMargin + bottomMargin"
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="auto"
                        v-if="
                          component.avatar.placement === 'left' &&
                            component.avatar.image &&
                            component.avatar.show
                        "
                      >
                        <v-row
                          :justify="component.avatar.justify"
                          :style="
                            'height: ' + component.avatar.boxHeight + 'px;'
                          "
                        >
                          <media-upload
                            :id="currentUser._id"
                            :currentImageOnFile="currentUser.image"
                            :externalImageUrl="currentUser.externalImageUrl"
                            :showMetaData="false"
                            @onImageSaved="handleImageSaved"
                            @onImageProcessing="handleImageProcessing"
                            :radius="false"
                            :avatar="true"
                            :avatarSize="component.avatar.height"
                          />
                        </v-row>
                      </v-col>
                      <v-col>
                        <v-row
                          :justify="component.avatar.justify"
                          :style="
                            'height: ' + component.avatar.boxHeight + 'px;'
                          "
                        >
                          <media-upload
                            v-if="
                              component.avatar.placement === 'top' &&
                                component.avatar.image &&
                                component.avatar.show
                            "
                            class=""
                            :id="currentUser._id"
                            :currentImageOnFile="currentUser.image"
                            :externalImageUrl="currentUser.externalImageUrl"
                            :showMetaData="false"
                            @onImageSaved="handleImageSaved"
                            @onImageProcessing="handleImageProcessing"
                            :radius="false"
                            :avatar="true"
                            :avatarSize="component.avatar.height"
                          />
                        </v-row>

                        <view-text
                          v-show="component.name.show"
                          :settings="component.name"
                          :value="
                            currentUser.firstname + ' ' + currentUser.lastname
                          "
                        ></view-text>
                        <div class="mb-4">
                          <v-row
                            v-for="(company, i) in currentUser.companies"
                            :key="'company' + i"
                            no-gutters
                            justify="center"
                            align="center"
                          >
                            <v-col>
                              <view-text
                                v-show="currentUser.companies.length > 0"
                                :settings="component.email"
                                :value="getCompanyName(company)"
                              ></view-text>
                            </v-col>
                          </v-row>
                        </div>

                        <view-text
                          v-show="component.email.show"
                          :settings="component.email"
                          :value="currentUser.email"
                        ></view-text>
                        <view-text
                          v-show="component.phone.show"
                          :settings="component.phone"
                          :value="currentUser.phone"
                        ></view-text>
                        <view-text
                          v-show="component.points.show"
                          :settings="component.points"
                          :value="currentUser.balance | number"
                          :suffix="suffix"
                        ></view-text>

                        <v-row
                          :justify="component.avatar.justify"
                          :style="
                            'height: ' + component.avatar.boxHeight + 'px;'
                          "
                        >
                          <media-upload
                            v-if="
                              component.avatar.placement === 'bottom' &&
                                component.avatar.image &&
                                component.avatar.show
                            "
                            class=""
                            :id="currentUser._id"
                            :currentImageOnFile="currentUser.image"
                            :externalImageUrl="currentUser.externalImageUrl"
                            :showMetaData="false"
                            @onImageSaved="handleImageSaved"
                            @onImageProcessing="handleImageProcessing"
                            :radius="false"
                            :avatar="true"
                            :avatarSize="component.avatar.height"
                          />
                        </v-row>
                      </v-col>
                      <v-col
                        cols="auto"
                        v-if="
                          component.avatar.placement === 'right' &&
                            component.avatar.image &&
                            component.avatar.show
                        "
                      >
                        <v-row
                          :justify="component.avatar.justify"
                          :style="
                            'height: ' + component.avatar.boxHeight + 'px;'
                          "
                        >
                          <media-upload
                            class=""
                            :id="currentUser._id"
                            :currentImageOnFile="currentUser.image"
                            :externalImageUrl="currentUser.externalImageUrl"
                            :showMetaData="false"
                            @onImageSaved="handleImageSaved"
                            @onImageProcessing="handleImageProcessing"
                            :radius="false"
                            :avatar="true"
                            :avatarSize="component.avatar.height"
                          />
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-col>
            </v-row>
          </template>

          <template v-if="component.settings.mode === 'image'">
            <v-img
              :src="component.background.image"
              :gradient="gradient"
              :height="imageHeight"
            >
              <v-row
                no-gutters
                :justify="component.block.justify"
                :align="component.block.align"
                class="fill-height"
              >
                <v-col xs="12" :sm="component.block.cols">
                  <v-col>
                    <v-card
                      tile
                      elevation="0"
                      :class="titleBlock"
                      :style="topMargin + bottomMargin"
                    >
                      <v-row no-gutters>
                        <v-col
                          cols="auto"
                          v-if="
                            component.avatar.placement === 'left' &&
                              component.avatar.image &&
                              component.avatar.show
                          "
                        >
                          <v-row
                            :justify="component.avatar.justify"
                            :style="
                              'height: ' + component.avatar.boxHeight + 'px;'
                            "
                          >
                            <media-upload
                              :id="currentUser._id"
                              :currentImageOnFile="currentUser.image"
                              :externalImageUrl="currentUser.externalImageUrl"
                              :showMetaData="false"
                              @onImageSaved="handleImageSaved"
                              @onImageProcessing="handleImageProcessing"
                              :radius="false"
                              :avatar="true"
                              :avatarSize="component.avatar.height"
                            />
                          </v-row>
                        </v-col>
                        <v-col>
                          <v-row
                            :justify="component.avatar.justify"
                            :style="
                              'height: ' + component.avatar.boxHeight + 'px;'
                            "
                          >
                            <media-upload
                              :id="currentUser._id"
                              :currentImageOnFile="currentUser.image"
                              :externalImageUrl="currentUser.externalImageUrl"
                              :showMetaData="false"
                              @onImageSaved="handleImageSaved"
                              @onImageProcessing="handleImageProcessing"
                              :radius="false"
                              :avatar="true"
                              :avatarSize="component.avatar.height"
                            />
                          </v-row>
                          <view-text
                            v-show="component.name.show"
                            :settings="component.name"
                            :value="
                              currentUser.firstname + ' ' + currentUser.lastname
                            "
                          ></view-text>
                          <view-text
                            v-show="component.email.show"
                            :settings="component.email"
                            :value="currentUser.email"
                          ></view-text>
                          <view-text
                            v-show="component.phone.show"
                            :settings="component.phone"
                            :value="currentUser.phone"
                          ></view-text>
                          <view-text
                            v-show="component.points.show"
                            :settings="component.points"
                            :value="currentUser.balance | number"
                            :suffix="suffix"
                          ></view-text>
                          <v-row
                            :justify="component.avatar.justify"
                            :style="
                              'height: ' + component.avatar.boxHeight + 'px;'
                            "
                          >
                            <media-upload
                              v-if="
                                component.avatar.placement === 'bottom' &&
                                  component.avatar.image &&
                                  component.avatar.show
                              "
                              :id="currentUser._id"
                              :currentImageOnFile="currentUser.image"
                              :externalImageUrl="currentUser.externalImageUrl"
                              :showMetaData="false"
                              @onImageSaved="handleImageSaved"
                              @onImageProcessing="handleImageProcessing"
                              :radius="false"
                              :avatar="true"
                              :avatarSize="component.avatar.height"
                            />
                          </v-row>
                        </v-col>
                        <v-col
                          cols="auto"
                          v-if="
                            component.avatar.placement === 'right' &&
                              component.avatar.image &&
                              component.avatar.show
                          "
                        >
                          <v-row
                            :justify="component.avatar.justify"
                            :style="
                              'height: ' + component.avatar.boxHeight + 'px;'
                            "
                          >
                            <media-upload
                              :id="currentUser._id"
                              :currentImageOnFile="currentUser.image"
                              :externalImageUrl="currentUser.externalImageUrl"
                              :showMetaData="false"
                              @onImageSaved="handleImageSaved"
                              @onImageProcessing="handleImageProcessing"
                              :radius="false"
                              :avatar="true"
                              :avatarSize="component.avatar.height"
                            />
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-col>
              </v-row>
            </v-img>
          </template>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MediaUpload from "../MediaUpload.vue";
import ViewText from "../Text.vue";

export default {
  components: {
    MediaUpload,
    ViewText
  },
  props: ["component", "index"],
  data: function() {
    return {
      image: {
        name: "",
        updated: "",
        smallUrl: "",
        mediumUrl: "",
        largeUrl: "",
        smallStoragePath: "",
        mediumStoragePath: "",
        largeStoragePath: ""
      }
    };
  },
  created() {
    console.log(this.currentUser._id);
  },

  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    userDealer() {
      return this.$store.getters.userDealer;
    },
    suffix() {
      return this.$store.getters.currentProgram.points.label;
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme;
    },
    companies() {
      return this.$store.getters.companies;
    },
    gradient() {
      if (this.component.screen.type === "gradient") {
        var screen = this.component.screen;
        var gradient =
          screen.direction +
          ", rgba(" +
          screen.fromColor.rgba.r +
          ", " +
          screen.fromColor.rgba.g +
          ", " +
          screen.fromColor.rgba.b +
          ", " +
          screen.fromColor.rgba.a +
          ") " +
          "0%, rgba(" +
          screen.toColor.rgba.r +
          ", " +
          screen.toColor.rgba.g +
          ", " +
          screen.toColor.rgba.b +
          ", " +
          screen.toColor.rgba.a +
          ") " +
          screen.height +
          "%";
        return gradient;
      } else if (this.component.screen.type === "screen") {
        var screen2 = this.component.screen;
        var screenColor =
          screen2.direction +
          ", rgba(" +
          screen2.color.rgba.r +
          ", " +
          screen2.color.rgba.g +
          ", " +
          screen2.color.rgba.b +
          ", " +
          screen2.color.rgba.a +
          "), rgba(" +
          screen2.color.rgba.r +
          ", " +
          screen2.color.rgba.g +
          ", " +
          screen2.color.rgba.b +
          ", " +
          screen2.color.rgba.a +
          ")";
        return screenColor;
      } else {
        return "to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)";
      }
    },
    grid() {
      return this.$store.getters.grid;
    },
    topMargin() {
      let val = "";
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          val = 45;
          break;
        case "sm":
          val = 65;
          break;
        case "md":
          val = this.component.block.padding.top;
          break;
        case "lg":
          val = this.component.block.padding.top;
          break;
        case "xl":
          val = this.component.block.padding.top;
          break;
        default:
          console.log("There was an error");
      }
      return "padding-top: " + val + "px; ";
    },
    bottomMargin() {
      let val = "";
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          val = 45;
          break;
        case "sm":
          val = 65;
          break;
        case "md":
          val = this.component.block.padding.bottom;
          break;
        case "lg":
          val = this.component.block.padding.bottom;
          break;
        case "xl":
          val = this.component.block.padding.bottom;
          break;
        default:
          console.log("There was an error");
      }
      return "padding-bottom: " + val + "px; ";
    },
    titleBlock() {
      var padding = this.component.block.padding;
      return (
        this.grid.block +
        " pl-md-" +
        padding.left +
        " pr-md-" +
        padding.right +
        " pl-sm-" +
        Math.ceil(padding.left / 2) +
        " pr-" +
        Math.ceil(padding.left / 2)
      );
    },
    hasWebThemePendingUpdates() {
      return this.$store.getters.hasWebThemePendingUpdates;
    },
    height() {
      if (this.component.settings.heightContext == "px") {
        return this.component.heightValue.px + "px";
      } else {
        return this.component.heightValue.vh + "vh";
      }
    },
    imageHeight() {
      if (this.component.settings.heightContext == "px") {
        return this.component.heightValue.px + this.component.overlap.px + "px";
      } else {
        return this.component.heightValue.vh + this.component.overlap.vh + "vh";
      }
    },
    // image() {
    //   if (this.component.settings.mode === 'color') {
    //     return ''
    //   } else {
    //     return this.component.settings.image
    //   }
    // },
    menuMargin() {
      let val = 0;
      var height = this.webTheme.shared.menuHeight;
      var arr = this.webTheme.header.components;
      var result = arr.find(el => el.id === "MenuBar");
      if (result && result.settings.overlap == true) {
        val = "margin-top: " + height + "px; ";
      } else {
        val = "";
      }
      return val;
    }
  },
  methods: {
    handleEditor(val) {
      this.editing = val;
      if (val === "preview") {
        this.previewCols = 11;
      } else {
        this.previewCols = 8;
      }
    },
    onImageSaved() {
      console.log("onImageSaved clicked");
    },
    handleImageSaved(image) {
      console.log("handleImageSaved clicked");
      this.image = {
        updated: image.updated,
        name: image.name || "",
        smallUrl: image.smallUrl || "",
        mediumUrl: image.mediumUrl || "",
        largeUrl: image.largeUrl || "",
        smallStoragePath: image.smallStoragePath || "",
        mediumStoragePath: image.mediumStoragePath || "",
        largeStoragePath: image.largeStoragePath || ""
      };
      const payload = {
        ...this.currentUser,
        id: this.currentUser._id,
        image: this.image
      };
      this.$store.dispatch("updateMember", payload);
    },
    handleImageProcessing() {
      console.log("handleImageProcessing clicked");
    },
    getCompanyName(id) {
      const name = this.companies.find(el => el.id === id).title;
      if (name) {
        return name;
      }
      return "-";
    }
  }
};
</script>

<style scoped>
.theme-page {
  padding: 0px 10px 0px 0px !important;
}
.sticky-footer {
  position: fixed;
  background: transparent;
  bottom: 0px;
  left: 290px;
  margin-bottom: 10px;
  z-index: 1000 !important;
}
</style>

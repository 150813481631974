var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[(!_vm.permitted && !_vm.avatar)?_c('v-alert',{attrs:{"border":"left","elevation":"0","color":this.$store.getters.currentProgram.webTheme.colors.primary.hex,"type":"error"}},[_c('span',{staticClass:"pl-1 incentable-alert"},[_vm._v("Invalid file type. Images must be .jpg, .jpeg or .png")])]):_vm._e(),(_vm.avatar)?_c('div',[(_vm.imageUrl && _vm.permitted)?_c('div',[_c('v-avatar',{attrs:{"size":_vm.avatarSize ? _vm.avatarSize : '200'}},[_c('v-img',{attrs:{"src":_vm.imageUrl,"alt":"Image"}},[(_vm.processing)?_c('v-overlay',{attrs:{"absolute":"","transition":"fade-transition","opacity":"0.5"}},[(_vm.processing)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"80","width":"10","color":this.$store.getters.currentProgram.webTheme.colors.primary
                      .hex}}):_vm._e()],1):_vm._e()],1)],1)],1):(
            (_vm.currentImageOnFile.mediumUrl && !_vm.imageUrl) ||
              (_vm.externalImageUrl && !_vm.imageUrl) ||
              (_vm.currentImageOnFile.mediumUrl && !_vm.permitted)
          )?_c('div',{attrs:{"color":"white"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-avatar',{attrs:{"size":_vm.avatarSize ? _vm.avatarSize : '200'}},[_c('v-img',{class:_vm.radius ? 'radius hover' : 'hover',attrs:{"src":_vm.externalImageUrl
                    ? _vm.externalImageUrl
                    : _vm.currentImageOnFile.mediumUrl,"lazy-src":_vm.externalImageUrl
                    ? _vm.externalImageUrl
                    : _vm.currentImageOnFile.mediumUrl,"alt":"Image"}},[((hover && !_vm.externalImageUrl) || _vm.processing)?_c('v-overlay',{attrs:{"absolute":"","transition":"fade-transition","opacity":"0.5"}},[(!_vm.processing)?_c('v-btn',{attrs:{"color":this.$store.getters.currentProgram.webTheme.colors
                        .primary.hex,"fab":""},on:{"click":_vm.handleOpenFileBrowser}},[_c('v-icon',[_vm._v("photo_camera")])],1):_vm._e(),(!_vm.processing)?_c('v-btn',{attrs:{"color":this.$store.getters.currentProgram.webTheme.colors
                        .primary.hex,"fab":""},on:{"click":function($event){return _vm.handleDeleteMedia(false)}}},[_c('v-icon',[_vm._v("delete")])],1):_vm._e(),(_vm.processing)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"80","width":"10","color":this.$store.getters.currentProgram.webTheme.colors
                        .primary.hex}}):_vm._e()],1):_vm._e()],1)],1)]}}],null,false,3507763302)})],1):(_vm.externalImageUrl)?_c('div',[_c('v-card',{staticClass:"mb-5 pt-0 mt-0 rounded",attrs:{"flat":"","outlined":"","max-height":"300px"}},[_c('v-avatar',{attrs:{"size":_vm.avatarSize ? _vm.avatarSize : '200'}},[_c('v-img',{attrs:{"src":_vm.externalImageUrl ? _vm.externalImageUrl : _vm.imageUrl,"lazy-src":require('@/assets/placeholder.png'),"transition":"fade-transition","contain":""}})],1)],1)],1):_c('v-avatar',{staticClass:"pointer",attrs:{"color":"grey lighten-3","elevation":"0","size":_vm.avatarSize ? _vm.avatarSize : '200'},on:{"click":function($event){_vm.id ? _vm.handleOpenFileBrowser() : ''}}},[_c('v-row',{staticStyle:{"height":"300px"},attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-col',[_c('v-row',{attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-icon',{attrs:{"color":"grey lighten-2","size":"50"}},[_vm._v("photo_camera")])],1),(!_vm.id)?_c('v-row',{staticClass:"note",attrs:{"no-gutters":"","justify":"center","align":"center"}},[_vm._v(" The item must be saved before an image can be added ")]):_vm._e()],1)],1)],1),(!_vm.permitted && _vm.avatar)?_c('v-alert',{staticClass:"ml-n9 mr-6 mt-n12",attrs:{"border":"left","elevation":"0","color":this.$store.getters.currentProgram.webTheme.colors.primary.hex,"type":"error"}},[_c('span',{staticClass:"pl-1 incentable-alert"},[_vm._v("Invalid file type. Images must be .jpg, .jpeg or .png")])]):_vm._e()],1):_c('div',[(_vm.imageUrl && _vm.permitted)?_c('div',[_c('v-img',{attrs:{"src":_vm.imageUrl,"alt":"Image"}},[(_vm.processing)?_c('v-overlay',{attrs:{"absolute":"","transition":"fade-transition","opacity":"0.5"}},[(_vm.processing)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"80","width":"10","color":this.$store.getters.currentProgram.webTheme.colors.primary
                    .hex}}):_vm._e()],1):_vm._e()],1)],1):(
            (_vm.currentImageOnFile.mediumUrl && !_vm.imageUrl) ||
              (_vm.externalImageUrl && !_vm.imageUrl) ||
              (_vm.currentImageOnFile.mediumUrl && !_vm.permitted)
          )?_c('div',{attrs:{"color":"white"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-img',{class:_vm.radius ? 'radius hover' : 'hover',attrs:{"src":_vm.externalImageUrl
                  ? _vm.externalImageUrl
                  : _vm.currentImageOnFile.mediumUrl,"lazy-src":_vm.externalImageUrl
                  ? _vm.externalImageUrl
                  : _vm.currentImageOnFile.mediumUrl,"alt":"Image"}},[((hover && !_vm.externalImageUrl) || _vm.processing)?_c('v-overlay',{attrs:{"absolute":"","transition":"fade-transition","opacity":"0.5"}},[(!_vm.processing)?_c('v-btn',{attrs:{"color":this.$store.getters.currentProgram.webTheme.colors.primary
                      .hex,"fab":""},on:{"click":_vm.handleOpenFileBrowser}},[_c('v-icon',[_vm._v("photo_camera")])],1):_vm._e(),(!_vm.processing)?_c('v-btn',{attrs:{"color":this.$store.getters.currentProgram.webTheme.colors.primary
                      .hex,"fab":""},on:{"click":function($event){return _vm.handleDeleteMedia(false)}}},[_c('v-icon',[_vm._v("delete")])],1):_vm._e(),(_vm.processing)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"80","width":"10","color":this.$store.getters.currentProgram.webTheme.colors.primary
                      .hex}}):_vm._e()],1):_vm._e()],1)]}}])})],1):(_vm.externalImageUrl)?_c('div',[_c('v-card',{staticClass:"mb-5 pt-0 mt-0 rounded",attrs:{"flat":"","outlined":"","max-height":"300px"}},[_c('v-img',{attrs:{"src":_vm.externalImageUrl ? _vm.externalImageUrl : _vm.imageUrl,"lazy-src":require('@/assets/placeholder.png'),"transition":"fade-transition","contain":""}})],1)],1):_c('v-card',{staticClass:"pointer",attrs:{"color":"grey lighten-3","elevation":"0","height":"300px","width":"100%"},on:{"click":function($event){_vm.id ? _vm.handleOpenFileBrowser() : ''}}},[_c('v-row',{staticStyle:{"height":"300px"},attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-col',[_c('v-row',{attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-icon',{attrs:{"color":"grey lighten-2","size":"50"}},[_vm._v("photo_camera")])],1),(!_vm.id)?_c('v-row',{staticClass:"note",attrs:{"no-gutters":"","justify":"center","align":"center"}},[_vm._v(" The item must be saved before an image can be added ")]):_vm._e()],1)],1)],1)],1),(_vm.showMetaData && !_vm.externalImageUrl && _vm.currentImageOnFile.name)?_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('v-col',[_c('v-row',{staticClass:"image-meta-heading",attrs:{"no-gutters":"","justify":"end"}},[_vm._v(" File Name: ")])],1),_c('v-col',{staticClass:"pl-1",attrs:{"cols":"auto"}},[_c('v-row',{staticClass:"image-meta",attrs:{"no-gutters":""}},[_vm._v(" "+_vm._s(_vm.currentImageOnFile.name)+" ")])],1)],1):_vm._e()],1)],1),_c('input',{ref:"imageFileInput",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.handleFilePicked}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }